import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import produce from "immer";
import type { AnyAction } from "redux";

//
// -----------------------------------
// STATE
//
const STATE_KEY = "mobileBlocker";

type MobileBlockerState = {
  hidden: boolean;
};

type MobileBlockerSlice = {
  [STATE_KEY]: MobileBlockerState;
};

const initialState: MobileBlockerState = {
  hidden: false,
};

//
// -----------------------------------
// ACTIONS
//
const ActionTypes = {
  DISMISS_MOBILE_BLOCKER: "mobileBlocker/DISMISS_MOBILE_BLOCKER",
};

//
// -----------------------------------
// ACTION CREATORS
//
export function dismissMobileBlocker() {
  return {
    type: ActionTypes.DISMISS_MOBILE_BLOCKER,
  };
}

//
// ----------------------------
// HANDLERS
//
const mobileBlockerReducer = produce((draft: MobileBlockerState, action: AnyAction) => {
  if (action.type === ActionTypes.DISMISS_MOBILE_BLOCKER) {
    draft.hidden = true;
  }
}, initialState);

//
// -----------------------------------------
// SELECTORS
//
export const selectIsMobileBlockerDismissed: (state: MobileBlockerSlice) => boolean = (state) =>
  state?.[STATE_KEY]?.hidden;

//
// -------------------------------
// Setup
//
const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, mobileBlockerReducer);
};

export default install;
