/*
  Helper function for creating actions.
  Will throw if any string is used twice as an action name.
*/

const used: string[] = [];

export default function createAction(type: string) {
  if (used.includes(type)) {
    throw new Error(`Duplicate action: ${type}`);
  }
  used.push(type);
  return type;
}
