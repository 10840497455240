/*
  Helper function for creating async actions.

  Returns an array of up to three actions:
    - [actionName]
    - [actionName]_done
    - [actionName]_fail

  Will throw if any string is used twice as an action name.
*/

import createAction from "./createAction";

export default function createAsyncActions(baseType: string) {
  return [baseType, `${baseType}_done`, `${baseType}_fail`].map(createAction);
}
