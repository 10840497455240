import type { FC } from "react";
import { T } from "../../i18n";
import {
  ERROR_MESSAGES,
  getErrorCode,
  isPresentableErrorCode,
} from "../../errors";

type Props = {
  className?: string;
  for: unknown;
};

const getErorrMessage = (error: unknown) => {
  const errorCode = getErrorCode(error);
  if (isPresentableErrorCode(errorCode)) {
    return ERROR_MESSAGES[errorCode];
  }

  return null;
};

const ErrorMessage: FC<Props> = ({ className, for: error }) => {
  const message = getErorrMessage(error);
  if (message) {
    return (
      <p className={className}>
        <T {...message} />
      </p>
    );
  }

  return null;
};

export default ErrorMessage;
