import { makeApiMutation } from "../../reactQuery";
import { useMessageThreadFetcher } from "./fetchers";

export const useCustomizeThreadOperation = makeApiMutation({
  name: "customizeThread",
  path: "/api/message-thread/{messageThreadId}",
  method: "put",
  onSuccess: (_data, params) => {
    useMessageThreadFetcher.invalidateQueries({ messageThreadId: params.path.messageThreadId });
  },
});
