import { components } from "@classdojo/ts-api-types/api";
import callApi from "@web-monorepo/infra/callApi";
import {
  APIResponse,
  CollectionFetcherReturnType,
  MemberFetcherReturnType,
} from "@web-monorepo/shared/api/apiTypesHelper";
import { DojoError } from "@web-monorepo/shared/errors/errorTypeMaker";
import { makeApiMutation, makeCollectionQuery, makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import pick from "lodash/pick";
import errors from "app/errors";
import { useSessionFetcher } from "app/pods/session";
import matchesError from "app/utils/matchesError";
import { useUserConfigFetcher } from "app/pods/userConfig";

//
// -----------------------------------------
// FETCHERS
//
export const useFetchedParent = () => {
  const { data } = useSessionFetcher({});
  const parent = data && data.parent;
  return parent!;
};

export const useParentProfilePictureUrl = () => {
  const parent = useFetchedParent();
  return parent ? parent.avatarURL : undefined;
};

type UseDeleteAccountParams = components["schemas"]["UsernamePasswordPayload"] & { parentId: string };
type UseDeleteAccountResponse = APIResponse<"/api/session", "post">;

export const useDeleteAccountOperation = makeMutation<
  UseDeleteAccountParams,
  UseDeleteAccountResponse | void,
  DojoError
>({
  name: "deleteAccount",
  fn: async ({ parentId, login, password }) => {
    // validate parent credentials
    try {
      await callApi({
        method: "POST",
        path: "/api/session",
        body: { login, password, type: "parent" },
      });
    } catch (err) {
      // invalid password
      if (matchesError(err.response, "Incorrect password")) {
        return errors.login.password({ password });
      }

      if (!matchesError(err.response, "Must use one time code")) {
        throw err;
      }
    }

    // delete parent account
    await callApi({
      method: "DELETE",
      path: `/api/parent/${parentId}`,
    });

    window.location.reload();
  },
});

type UpdateParentResponse = APIResponse<"/api/parent/{id}", "put">;

type UpdateParentParams = {
  parent: Parent;
  propsToUpdate: Record<string, unknown>;
};

function getUpdateProfilePayload(parent: Parent, propsToUpdate: Record<string, unknown>) {
  const fields = [
    "_id",
    "locale",
    "firstName",
    "lastName",
    "emailAddress",
    "password",
    "avatarURL",
    "marketingEmailOptOut",
  ];
  return pick({ ...parent, ...propsToUpdate }, fields);
}

export const useUpdateParentOperation = makeMutation<UpdateParentParams, { body: UpdateParentResponse }, DojoError>({
  name: "updateParent",
  fn: async ({ parent, propsToUpdate }) => {
    try {
      return await callApi({
        method: "PUT",
        path: `/api/parent/${parent._id}`,
        body: getUpdateProfilePayload(parent, propsToUpdate),
      });
    } catch (err) {
      if (matchesError(err.response, "Email already exists")) {
        return errors.parent.emailAlreadyExist();
      }
      throw err;
    }
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
    useUserConfigFetcher.invalidateQueries();
  },
});

type UseUpdateProfilePictureParams = {
  parent: Parent;
  url?: string;
};
type UseUpdateProfilePictureResponse = APIResponse<"/api/parent/{id}", "put">;

export const useUpdateProfilePictureOperation = makeMutation<
  UseUpdateProfilePictureParams,
  { body: UseUpdateProfilePictureResponse }
>({
  name: "updateProfilePicture",
  fn: async ({ parent, url }) => {
    return await callApi({
      method: "PUT",
      path: `/api/parent/${parent._id}`,
      body: getUpdateProfilePayload(parent, { avatarURL: url }),
    });
  },
  onMutate: (params) => {
    useSessionFetcher.setQueriesData((draft) => {
      if (!draft.parent) return draft;

      draft.parent.avatarURL = params.url;
    });
  },
});

export type EmailValidationCode = MemberFetcherReturnType<typeof useEmailValidationFetcher>;

export const useEmailValidationFetcher = makeMemberQuery({
  fetcherName: "emailValidation",
  path: "/api/user/emailValidation/{email}",
});

export const useInvitationCodeFetcher = makeMemberQuery({
  fetcherName: "parentInvitationCode",
  path: "/api/invitation/{identifier}",
  dontThrowOnStatusCodes: [400, 404],
});
export type InvitationCode = MemberFetcherReturnType<typeof useInvitationCodeFetcher>;

export const useParentReconnectingDataFetcher = makeCollectionQuery({
  fetcherName: "parentReconnectingData",
  path: "/api/parentReconnecting",
});

export type ParentReconnecting = CollectionFetcherReturnType<typeof useParentReconnectingDataFetcher>;

export const useDismissParentReconnectingClassOperation = makeMutation({
  name: "dismissParentReconnecting",
  fn: async ({ classId }) => {
    return await callApi({
      method: "DELETE",
      path: `/api/parentReconnecting/${classId}`,
    });
  },
  onSuccess: () => {
    useParentReconnectingDataFetcher.invalidateQueries();
  },
});

export const useSendEmailVerification = makeApiMutation({
  name: "sendVerificationEmail",
  path: "/api/sendEmailVerification",
  method: "post",
});

export type SessionResponse = MemberFetcherReturnType<typeof useSessionFetcher>;
export type ParentSession = NonNullable<SessionResponse["parent"]>;
export type Parent = NonNullable<MemberFetcherReturnType<typeof useSessionFetcher>["parent"]>;
