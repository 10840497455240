import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import { useParentMessageThreadsFetcher, useClassMessageThreadsFetcher } from "./fetchers";

type UseLeaveThreadParams = {
  messageThreadId: string;
  entityType: "parent" | "teacher";
};

export const useLeaveThreadOperation = makeMutation<UseLeaveThreadParams, unknown>({
  name: "useLeaveThread",
  fn: async ({ messageThreadId }) =>
    callApi({
      method: "DELETE",
      path: `/api/message-thread/${messageThreadId}/participants/self`,
    }),
  onMutate: ({ messageThreadId, entityType }) => {
    if (entityType === "parent") {
      useParentMessageThreadsFetcher.setQueriesData((cacheData) => {
        return cacheData.filter((thread) => thread._id !== messageThreadId);
      });
    } else {
      useClassMessageThreadsFetcher.setQueriesData((cacheData) => {
        return cacheData.filter((thread) => thread._id !== messageThreadId);
      });
    }
  },
  onError: async (_data, { entityType }) => {
    if (entityType === "parent") {
      return await useParentMessageThreadsFetcher.invalidateQueries();
    } else {
      return await useClassMessageThreadsFetcher.invalidateQueries();
    }
  },
});
