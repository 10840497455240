import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import {
  Message,
  ReportedReason,
  ReportMessageRequestBody,
  ThreadParticipant,
  ReportParticipantRequestBody,
} from "../types";

type ReportMessageParams = {
  message: Message;
  reason: ReportedReason;
  details: string;
};

export const useReportMessageOperation = makeMutation<ReportMessageParams, void>({
  name: "reportMessage",
  fn: async ({ message, reason, details }) => {
    const body: ReportMessageRequestBody = {
      reason,
      details,
    };

    try {
      const res = await callApi({
        method: "POST",
        path: `/api/message-thread/${message.messageThreadId}/report/message/${message._id}`,
        body,
      });

      return res.body;
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        return error;
      }

      throw error;
    }
  },
});

type ReportParticipantParams = {
  messageThreadId: string;
  participant: ThreadParticipant;
  reason: ReportedReason;
  details: string;
};

export const useReportParticipantOperation = makeMutation<ReportParticipantParams, void>({
  name: "reportParticipant",
  fn: async ({ messageThreadId, participant, reason, details }) => {
    const body: ReportParticipantRequestBody = {
      reason,
      details,
    };

    try {
      const res = await callApi({
        method: "POST",
        path: `/api/message-thread/${messageThreadId}/report/entity/${participant._id}`,
        body,
      });

      return res.body;
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        return error;
      }

      throw error;
    }
  },
});
