import * as logClient from "@classdojo/log-client";
import { Attribution } from "@classdojo/web/hooks/useAttribution";
import callApi from "@web-monorepo/infra/callApi";
import { APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { DojoError } from "@web-monorepo/shared/errors/errorTypeMaker";
import { makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import errors from "app/errors";
import { FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";
import matchesError from "app/utils/matchesError";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import { Response } from "superagent";

function getApiError(response: Response, email?: string, mode?: "auto" | "manual") {
  const metadata = {
    current_site: "parent",
    account_type: "parent",
    signup_type: "email",
  };
  if (response.status === 400) {
    if (matchesError(response, "Invitation not found")) {
      logClient.logEvent({
        eventName: "web.signup.failure",
        metadata: {
          ...metadata,
          failure_reason: "invitationNotFound",
        },
      });
      return errors.parentCode.notFound();
    } else if (matchesError(response, "Email already exists")) {
      const regex = /.+user type: (\w+)/g;
      const detail = response.body.error.detail;
      const type = regex.exec(detail)?.[1];
      // hunting for user type from the 400 response, so that we can lead the user to the right login place
      logClient.logEvent({
        eventName: "web.signup.failure",
        metadata: {
          ...metadata,
          failure_reason: "emailExists",
          type,
          mode,
        },
      });
      const error = errors.signup.email({ email, existingAccountType: type });
      logClient.logEvent("parent.signupError.existingEmail");
      return error;
    } else if (matchesError(response, "Password must not contain common passwords")) {
      logClient.logEvent({
        eventName: "web.signup.failure",
        metadata: {
          ...metadata,
          failure_reason: "commonPassword",
        },
      });
      return errors.signup.commonPassword();
    } else if (matchesError(response, "Invalid password")) {
      logClient.logEvent({
        eventName: "web.signup.failure",
        metadata: {
          ...metadata,
          failure_reason: "invalidPassword",
        },
      });
      return errors.signup.insecurePassword();
    } else {
      logClient.logEvent({
        eventName: "web.signup.failure",
        metadata: {
          ...metadata,
          failure_reason: "unknown",
        },
      });
      return errors.api.unknown();
    }
  } else {
    logClient.logEvent({
      eventName: "web.signup.failure",
      metadata: {
        ...metadata,
        failure_reason: "unknown",
      },
    });
    return errors.api.unknown();
  }
}

//
// -------------------------------
// Setup
//
export default function install() {}

export const useNeedsDataTransferConsentFetcher = makeMemberQuery({
  path: "/api/needsDataTransferConsent",
  fetcherName: "needsDataTransferConsentMember",
});

type RegisterParentParams = {
  parentCode?: string;
  classInviteCode: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  locale: string;
  dataTransferConsentGranted?: boolean;
  attributionData: Attribution;
  mode?: "auto" | "manual";
};
type RegisterParentResponse = APIResponse<"/api/parent", "post">;

export const useParentRequestPassword = makeMutation<void, { body: void; statusCode?: number }, DojoError>({
  name: "parentRequestPassword",
  fn: async () => {
    try {
      const result = await callApi({ method: "POST", path: "/api/parentRequestPassword" });
      logClient.logEvent("parent.signup.passwordless.password_requested");
      return result;
    } catch (err) {
      if (err.response) {
        return getApiError(err.response);
      }
      throw err;
    }
  },
});

export const logParentSignup = ({
  id,
  parentCode,
  classInviteCode,
  isNewAccount,
  signupType = "email",
  attributionData = {},
}: {
  id: string;
  parentCode?: string | undefined;
  classInviteCode?: string | undefined;
  attributionData?: Attribution;
  signupType?: "email" | "google";
  isNewAccount: boolean;
}) => {
  logClient.logEvent({
    entityId: id,
    eventName: "parent.signup.finish",
    metadata: {
      parentCode: parentCode || "none",
      classInviteCode: classInviteCode || "none",
    },
  });

  if (isNewAccount) {
    logClient.logConvertExperiment(FEATURE_SWITCHES.WEB_EXTERNAL_LOGIN_TO_HOME);

    if (signupType == "google") {
      logClient.logEvent({
        entityId: id,
        eventName: "web.signup.success.google",
        experiments: [FEATURE_SWITCHES.WEB_EXTERNAL_LOGIN_TO_HOME],
        metadata: {
          current_site: "parent",
          account_type: "parent",
          signup_type: signupType,
          ...attributionData,
        },
      });
    }

    logClient.logEvent({
      entityId: id,
      eventName: "web.signup.success",
      experiments: [FEATURE_SWITCHES.WEB_EXTERNAL_LOGIN_TO_HOME],
      metadata: {
        current_site: "parent",
        account_type: "parent",
        signup_type: signupType,
        ...attributionData,
      },
    });
  }
};

export const useRegisterParentOperation = makeMutation<
  RegisterParentParams,
  { body: RegisterParentResponse; statusCode?: number; created?: boolean },
  DojoError
>({
  name: "registerParent",
  fn: async ({
    parentCode,
    classInviteCode,
    firstName,
    lastName,
    email,
    password,
    locale /*, redirectTo */,
    attributionData,
    mode,
  }) => {
    const body = omitBy(
      {
        invitationId: parentCode,
        firstName,
        lastName,
        emailAddress: email,
        password,
        locale,
      },
      isUndefined,
    );

    try {
      const result: Response & { created?: boolean } = await callApi({
        method: "POST",
        path: "/api/parent",
        body,
      });

      logParentSignup({
        id: result.body._id,
        attributionData,
        classInviteCode,
        isNewAccount: result.created ?? false,
        parentCode,
      });

      if (result.statusCode === 200) {
        logClient.logEvent("parent.signup.loginfromsignup");
      }

      return result;
    } catch (err) {
      if (err.response) {
        return getApiError(err.response, email, mode);
      }
      const wrongPassword = matchesError(err.response, "Incorrect password");
      const wrongUsername = matchesError(err.response, "Incorrect username");

      if (wrongPassword) {
        logClient.logEvent({
          eventName: "web.signup.failure.wrongPassword",
        });
      }

      logClient.logEvent({
        eventName: "web.signup.failure",
        metadata: {
          current_site: "parent",
          account_type: "parent",
          signup_type: "email",
          failure_reason: wrongPassword ? "wrong_password" : wrongUsername ? "wrong_username" : "",
        },
      });

      throw err;
    }
  },
});

export const useValidateSignUpEmailFetcher = makeMemberQuery({
  path: "/api/user/emailValidation/{email}",
  fetcherName: "validateSignUpEmail",
});
