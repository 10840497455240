import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import { Message } from "../types";
import { useMessagesFetcher } from "./fetchers";

export const useMessageTranslationOperation = makeMutation<
  { messageThreadId?: string; messageId: string; language?: string; classId?: string },
  Message
>({
  name: "translateMessage",
  fn: async ({ messageThreadId, messageId, language, classId }) => {
    if (!messageThreadId || !language) return;
    // is a classId is given, we will use the old resource;

    if (classId) {
      const response = await callApi({
        path: `/api/dojoClass/${classId}/message/${messageId}`,
        query: { translateTo: language },
      });

      return response.body;
    } else {
      const response = await callApi({
        method: "POST",
        path: `/api/message-thread/${messageThreadId}/message/${messageId}/translate`,
        body: { language },
        headers: {
          "Cache-Control": "no-cache",
        },
      });

      return response.body;
    }
  },
  onSuccess: (data, params) => {
    useMessagesFetcher.setQueriesData((draft) => {
      for (const message of draft) {
        if (message._id === params.messageId) {
          message.translation = data.translation;
        }
      }
    });
  },
});
