import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import { useMessageThreadFetcher } from "./fetchers";

type AddParticipantsParams = {
  messageThreadId: string;
  participants: Array<{ _id: string; type: "parent" | "teacher" }>;
};

export const useAddParticipantOperation = makeMutation<AddParticipantsParams, void>({
  name: "addParticipants",
  fn: async ({ messageThreadId, participants }) => {
    const res = await callApi({
      method: "POST",
      path: `/api/message-thread/${messageThreadId}/participants`,
      body: { participants },
    });

    return res.body;
  },
  onSuccess: (_data, params) => {
    useMessageThreadFetcher.invalidateQueries({ messageThreadId: params.messageThreadId });
  },
});
