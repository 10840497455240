import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import { useMessageThreadFetcher } from "./fetchers";

type UseRemoveFromThreadParams = {
  messageThreadId: string;
  entityId: string;
};

export const useRemoveFromThreadOperation = makeMutation<UseRemoveFromThreadParams, unknown>({
  name: "useRemoveFromThread",
  fn: async ({ messageThreadId, entityId }) =>
    callApi({
      method: "DELETE",
      path: `/api/message-thread/${messageThreadId}/participants/${entityId}`,
    }),

  onSuccess: (_data, params) => {
    useMessageThreadFetcher.invalidateQueries({ messageThreadId: params.messageThreadId });
  },
});
