import { type FC, useCallback, useEffect, useState } from "react";
import { DDSButton } from "@web-monorepo/dds";
import { type Translator, useTranslate } from "../../i18n";

const COUNTDOWN_SECONDS = 30;
const SECONDS_TO_SHOW_CODE_SENT = 3;

const useCountdown = () => {
  const [countdown, setCountdown] = useState(COUNTDOWN_SECONDS);
  const startCountdown = useCallback(
    () => setCountdown(COUNTDOWN_SECONDS + SECONDS_TO_SHOW_CODE_SENT),
    [setCountdown],
  );

  useEffect(() => {
    if (!countdown) return;

    const intervalId = setTimeout(() => {
      setCountdown((c) => c - 1);
    }, 1000);

    return () => clearTimeout(intervalId);
  }, [countdown, setCountdown]);

  return [countdown, startCountdown] as const;
};

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type Props = Pick<ButtonProps, "onClick">;

const getText = (translate: Translator, countdown: number): string => {
  if (countdown > COUNTDOWN_SECONDS) {
    return translate({
      str: "dojo.common:otp_entry.code_sent",
      fallback: "Code sent",
    });
  } else if (countdown > 0) {
    return translate({
      str: "dojo.common:otp_entry.resend_code_in",
      fallback: "Resend code (__seconds__)",
      subs: { seconds: countdown },
    });
  }

  return translate({
    str: "dojo.common:otp_entry.resend_code",
    fallback: "Resend code",
  });
};

const ResendCodeButton: FC<Props> = ({ onClick }) => {
  const [countdown, startCountdown] = useCountdown();
  const translate = useTranslate();

  return (
    <DDSButton
      variant="tertiary"
      size="large"
      data-name="otp_entry.resend_code"
      disabled={countdown > 0}
      onClick={(event) => {
        startCountdown();
        onClick?.(event);
      }}
    >
      {getText(translate, countdown)}
    </DDSButton>
  );
};

export default ResendCodeButton;
