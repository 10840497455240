import type { FC } from "react";
import { DDSButton } from "@web-monorepo/dds";
import { IconX } from "@web-monorepo/dds-icons";
import { T } from "../i18n";
import { ErrorMessage, Mojo, OTCInput, ResendCodeButton } from "./components";

import imgSrc from "./letter.png";
import styles from "./VerificationPage.module.css";
import { useViewModel } from "./VerificationPage.vm";

export type Props = {
  error: unknown;
  onCancel(): void;
  onCode(code: string): void;
  entityType: "teacher" | "parent";
};

const instructionsId = "one_time_code.verification.label.id";

const VerificationPage: FC<Props> = ({
  entityType,
  error,
  onCode,
  onCancel,
}) => {
  const vm = useViewModel({ entityType, onCode });

  return (
    <>
      <nav className={styles.nav}>
        <Mojo className={styles.mojo} />
        <DDSButton
          data-name="otp_entry.close"
          variant="secondary"
          onClick={onCancel}
        >
          <IconX />
        </DDSButton>
      </nav>
      <div className={styles.content}>
        <img src={imgSrc} alt="" />
        <h1 className={styles.headline} id={instructionsId}>
          <T
            str="dojo.common:otp_entry.enter_code"
            fallback="Enter the 6-digit code"
          />
        </h1>
        {vm.instructionsHtml && (
          <p
            className={styles.instructions}
            dangerouslySetInnerHTML={{ __html: vm.instructionsHtml }}
          />
        )}
        <form className={styles.form} onSubmit={vm.handleSubmit}>
          <OTCInput
            formError={error}
            labeledBy={instructionsId}
            onChange={vm.handleChange}
          />
          <ErrorMessage for={error} className={styles.error} />

          <DDSButton
            data-name="otp_entry.verify_code"
            variant="primary"
            size="large"
            type="submit"
            disabled={!vm.canSubmit}
            width="fit"
          >
            <T str="dojo.common:otp_entry.verify" fallback="Verify" />
          </DDSButton>

          {/* TODO: calling onCode with an empty string should be practically the same as firing the original request (which should re-trigger an email).  */}
          <ResendCodeButton onClick={() => onCode("")} />
        </form>
      </div>
    </>
  );
};

export default VerificationPage;
