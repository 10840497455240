import * as logClient from "@classdojo/log-client";
import { LoadingMojo, LoggerContextSetter } from "@classdojo/web";
import useLogMarketingPageview from "@web-monorepo/shared/utils/useLogMarketingPageview";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEnsureFeatureSwitches, useFetchedFeatureSwitchesWithOverrides } from "app/pods/featureSwitches";
import { SCHOOL_FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";
import { useTotalUnreadThreadMessageCount } from "app/pods/messaging";
import { useFetchedParent } from "app/pods/parent";
import { useSessionFetcher } from "app/pods/session";
import { useUserConfigFetcher } from "app/pods/userConfig";
import pubnub from "app/pubnub";
import I18NContainer from "app/views/containers/I18NContainer";
import { setUser, setContext } from "@web-monorepo/telemetry";
/* eslint-disable-next-line @web-monorepo/no-css-file-imports */
import "@classdojo/web/pods/font/dojoText.css";
import { useLocationFetcher } from "app/pods/location";

// Implemented as a component and not as a hook because we want to save
// the time as soon as _all_ of the prerequisites getting to the inside of
// ApplicationContainer.
let firstTimeReady = false;
export const LogPrerequisitesMetadata = ({ children }: React.PropsWithChildren) => {
  if (firstTimeReady) {
    return <>{children}</>;
  }

  const elapsed = Math.round(performance.now());

  logClient.logEvent({
    eventName: "web.home.prerequisites-done",
    metadata: {
      elapsed,
    },
  });
  logClient.setMetadata("msToPrerequisites", elapsed);
  logClient.sendMetrics([
    {
      metricName: "web.home.msToPrerequisites",
      type: "distribution",
      value: elapsed,
      tags: { version: `${window.appVersion || "not-found"}` },
    },
  ]);

  firstTimeReady = true;

  return <>{children}</>;
};

const ApplicationContainer = (props: { children: React.ReactNode }) => {
  usePageTitle();
  useConfigureLogClientFromQueryString();

  const { error: sessionError, data: session } = useSessionFetcher({});
  const { data: locationData } = useLocationFetcher({});
  const location = useLocation();

  const parent = session && session.parent;

  const hasFinishedFetchingSession = !!(sessionError || session);
  const hasFinishedFetchingLocation = !!locationData;
  const authenticated = hasFinishedFetchingSession && !!session && !!session.parent;

  const parentId = parent?._id;

  useEffect(() => {
    if (parentId) {
      pubnub.init(parentId);
    }
  }, [parentId]);

  useLogMarketingPageview(hasFinishedFetchingSession);

  if (!hasFinishedFetchingSession || !hasFinishedFetchingLocation) {
    if (location.pathname.includes("signup")) {
      return null;
    }
    return (
      <div>
        <LoadingMojo noOverlay border />
      </div>
    );
  }

  const EnsureConfigContainer = authenticated ? EnsureUserConfigContainer : EnsurePublicUserConfigContainer;
  return (
    <I18NContainer locale={authenticated && session?.parent?.locale ? session.parent.locale : null}>
      <EnsureConfigContainer>
        <EnsureLoggerContext>
          <LogPrerequisitesMetadata>{props.children}</LogPrerequisitesMetadata>
        </EnsureLoggerContext>
      </EnsureConfigContainer>
    </I18NContainer>
  );
};

export default ApplicationContainer;

//
// ------------------
// Sub components
//

//
// Trigger more initializing for the stuff that we need when we don't have a session
//
const EnsurePublicUserConfigContainer = ({ children }: { children: React.ReactElement }) => {
  const { ready: isFeatureSwitchesReady } = useEnsureFeatureSwitches();

  if (!isFeatureSwitchesReady) {
    return (
      <div>
        <LoadingMojo noOverlay border />
      </div>
    );
  }

  return children;
};

//
// Trigger more initializing for the stuff that we need once we know the session
//
const EnsureUserConfigContainer = ({ children }: { children: React.ReactElement }) => {
  // Making sure that ANYBODY down the tree after here can use useFetchedParent
  const { data: parentResponse } = useSessionFetcher({});
  const parentId = parentResponse?.parent?._id;

  const { ready: isFeatureSwitchesReady } = useEnsureFeatureSwitches();

  const { data: userConfig } = useUserConfigFetcher({});

  const location = useLocation();

  useEffect(() => {
    if (parentId) {
      logClient.setEntityId(parentId);
    }

    setUser(parentId ? { id: parentId, type: "parent" } : null);
  }, [parentId]);

  // Show loading until we ensure we have all we need
  if (!userConfig || !isFeatureSwitchesReady || !parentResponse) {
    if (location.pathname.includes("signup")) {
      return null;
    }
    return (
      <div>
        <LoadingMojo noOverlay border />
      </div>
    );
  }

  return children;
};

const usePageTitle = () => {
  const [originalPageTitle] = useState(document.title);
  const unreadThreadCount = useTotalUnreadThreadMessageCount();

  // Either use the default page title or process one based in our app state.
  const pageTitle = unreadThreadCount ? `(${unreadThreadCount}) ${originalPageTitle}` : originalPageTitle;

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);
};

// Not entirely sure how we use this, but external site can send a sessionId:
// https://github.com/classdojo/external-site/blob/master/src/assets/js/app/app.coffee#L128

const useConfigureLogClientFromQueryString = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");

  useEffect(() => {
    if (sessionId) {
      logClient.setSessionId(sessionId);
    }
    setContext("session", { id: sessionId });
  }, [sessionId]);
};

const EnsureLoggerContext = ({ children }: { children: React.ReactElement }) => {
  const parent = useFetchedParent();
  const fetchedFeatureSwitches = useFetchedFeatureSwitchesWithOverrides();
  const entityType = parent ? "parent" : "preSignUp";
  // provide default school feature switch values of off for parents that are not part of a school
  // and didnt fetch those switches
  Object.values(SCHOOL_FEATURE_SWITCHES).forEach((schoolFeatureSwitch) => {
    if (!fetchedFeatureSwitches[schoolFeatureSwitch]) {
      fetchedFeatureSwitches[schoolFeatureSwitch] = "off";
    }
  });

  return (
    <>
      <LoggerContextSetter prefix="home" entityType={entityType} fetchedFeatureSwitches={fetchedFeatureSwitches} />
      {children}
    </>
  );
};
