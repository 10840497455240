import { makeMemberQuery } from "@web-monorepo/shared/reactQuery";

export const useLocationFetcher = makeMemberQuery({
  path: "/api/location",
  fetcherName: "fetchLocation",
});

export function useFetchedLocation() {
  const { data } = useLocationFetcher({});
  // TODO: do we have to? :/
  return data!;
}
