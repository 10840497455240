import { DDSButton } from "@web-monorepo/dds";

type Props = { logoutAction: () => void };

export const DevToolsLogoutButton = ({ logoutAction }: Props): JSX.Element => {
  return (
    <DDSButton data-name="dev_tools:logout_button" onClick={logoutAction}>
      <div>
        <div>Logout</div>
      </div>
    </DDSButton>
  );
};
