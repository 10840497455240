/** arrayToObject(["foo", "bar"] as const) === { FOO: "foo", BAR: "bar" } as const */
function arrayToObject<T extends string>(names: readonly T[]) {
  return names.reduce((r, n) => ({ ...r, [n.toUpperCase()]: n }), {} as { [K in T as Uppercase<K>]: K });
}

// The reason for having the feature switches in an isolated file is so Cypress can
// import them to use in the tests without having any other imports with aliases (absolute paths).
// That is because Cypress is not configured to understand aliases or absolute paths.
export const PARENT_FEATURE_SWITCHES = {
  ...arrayToObject([
    "api_force_one_time_code_login",
    "home_stripe_checkout_embedded_08_23_2024",
    "mweb_skip_plusconsent_v2",
    "mweb_success_app_upsell_copy",
    "web_2024_AUNZ_BTS_price_testing",
    "web_home_desktop_onboarding_upsell_aug_2024",
    "web_home_mobile_onboarding_upsell_design_variants_sept_2024",
    "web_home_loading_screen_sept_2024",
    "web_parent_verify_hashed_users_holdout",
    "web_parentPriceIncreaseHoldout_Aug_2024",
    "web_pcr_email_required_enabled",
    "web_double_confirm_upsell_modal_Sept_2024",
  ]),
  WEB_PARENT_COPARENTS_INVITE: "web_parent_coparents_invite_v2",
  MWEB_HIDE_CLOSE_ON_UPSELL: "mweb_hide_close_plus_app_upsell",
  WEB_PARENT_PCR_RELATION_INFO: "web_parent_pcr_relation_info_v2",
  WEB_PARENT_MFA_PROMO: "web_parent_mfa_promo",
} as const;

// Almost certainly, don't do school feature switches in home.
// Remember, parents are connected to multiple schools, so they'll have multiple values for this switch!
// This just picks the most "on" value, for easy testing purposes.
export const SCHOOL_FEATURE_SWITCHES: { [name: string]: string } = {
  // WARNING - you probably don't want to use this.  Parents can have no schools, single school, or multiple schools, ugh.
  // When we get a response back from api, it comes keyed by school0, school1, etc., which is impossible to correlate with an individual kids school
  // If we get conflicting non-off variant values, we throw them away
  // Probably the only use case is a school-based boolean switch, seeing if a parent has any kids in a non-off variant
};

// these feature switches will apply to any user session,
// whether the user is logged in or not
export const SESSION_FEATURE_SWITCHES = {
  ...arrayToObject(["web_home_password_complexity"]),
  WEB_EXTERNAL_LOGIN_TO_HOME: "redirect_parent_login_home_v2",
  WEB_HOME_RETRY_ASSET_WORKER: "home_retry_asset_worker",
  WEB_SIGNUP_VARIANT_SEPT_2024_V2: "web_signup_variant_sept_2024_v2",
  WEB_PARENT_SIGNUPCONTAINER_REFACTOR: "web_parent_signupcontainer_refactor",
} as const;

export const FEATURE_SWITCHES = {
  ...PARENT_FEATURE_SWITCHES,
  ...SCHOOL_FEATURE_SWITCHES,
  ...SESSION_FEATURE_SWITCHES,
} as const;

export type SchoolExperimentKey = keyof typeof SCHOOL_FEATURE_SWITCHES;
export type SchoolExperiment = (typeof SCHOOL_FEATURE_SWITCHES)[SchoolExperimentKey];

export type ParentExperimentKey = keyof typeof PARENT_FEATURE_SWITCHES;
export type ParentExperiment = (typeof PARENT_FEATURE_SWITCHES)[ParentExperimentKey];

export type SessionExperimentKey = keyof typeof SESSION_FEATURE_SWITCHES;
export type SessionExperiment = (typeof SESSION_FEATURE_SWITCHES)[SessionExperimentKey];

export type Experiment = SchoolExperiment | ParentExperiment | SessionExperiment;
