/*
  Helper function for creating action creators (...yep.)
  Takes two arguments, a (string) type and a payload-getter function.
  Ensures these are of the proper type and constructs a standard-format
  action using them.
*/

type GetPayload = (...args: unknown[]) => unknown;

const makeEmptyObject: GetPayload = () => ({});

export default function makeActionCreator(type: string, getPayload: GetPayload = makeEmptyObject) {
  if (typeof type !== "string") {
    throw new Error(`Invalid type: ${type}`);
  }

  if (typeof getPayload !== "function") {
    throw new Error(`Invalid getPayload: ${getPayload}`);
  }

  return (...args: unknown[]) => ({ type, payload: getPayload(...args) });
}
