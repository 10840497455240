/*
  Helper to construct action handlers.  These are the functions the reducer
  uses when handling actions.  Takes an action string and a handler fn.

  If you need to read the current state when handling the action, do this:

    createActionReducer(MY_ACTION, (payload, state) => {
      return set("value", state.value + payload.value, state);
    })

  However, if you don't need to read the current state when handling the action,
  we can simplify things by using currying:

    createActionReducer(MY_ACTION, (payload) => set("value", payload.value))

*/

import type { AnyAction } from "redux";
// Re-exporting the type so that clients don't need a reference to redux.
export type { AnyAction } from "redux";
import matchesAction from "./utils/matchesAction";

export default function createActionReducer(
  actionType: string,
  handler: (payload: unknown, state: unknown) => unknown,
) {
  return function (state: unknown, action: AnyAction) {
    let handlerResult;
    if (matchesAction(action, actionType)) {
      handlerResult = handler(action.payload, state);
      // allow a more concise argument signature if handling response
      // does not need to read state values.
      if (typeof handlerResult === "function") {
        handlerResult = handlerResult(state);
      }
    }
    return handlerResult || state;
  };
}
