import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import { TranslationObject } from "./i18n";

const HANDLED_ERROR_CODES = ["ERR_OTP_REQUIRED", "ERR_OTP_INVALID"] as const;

export type HandledErrorCode = (typeof HANDLED_ERROR_CODES)[number];

export const isInvalidCode = (error: unknown): boolean =>
  getErrorCode(error) === "ERR_OTP_INVALID";

export const ERROR_MESSAGES = {
  ERR_OTP_INVALID: {
    str: "dojo.common:otp_entry.invalid_code_error",
    fallback: "Incorrect code. Try again",
  },
} satisfies Partial<Record<HandledErrorCode, TranslationObject>>;

export const getErrorCode = (error: unknown): string => {
  if (error instanceof APIResponseError) {
    return error?.response?.body?.error?.code;
  }

  return "";
};

export const isHandledError = (error: unknown): error is APIResponseError =>
  HANDLED_ERROR_CODES.includes(getErrorCode(error)) ||
  getErrorCode(error)?.startsWith("ERR_MUST_USE_OTC");

export const isPresentableErrorCode = (
  code: string,
): code is keyof typeof ERROR_MESSAGES => code in ERROR_MESSAGES;

export const isPresentableError = (error: unknown) =>
  isPresentableErrorCode(getErrorCode(error));

export class UserCancelledCodeEntryError extends Error {
  /**
   * Most recent error that triggered OTP flow
   */
  public cause: Error;

  constructor(originalError: unknown) {
    const cause =
      originalError instanceof Error
        ? originalError
        : new Error(String(originalError));
    super("User cancelled OTP Entry", { cause });
  }
}
