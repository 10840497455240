/* eslint-disable @web-monorepo/no-style-prop */
import type { FC, SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const Mojo: FC<Props> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <circle cx={9.823} cy={2.92} r={2.92} fill="#A3E40D" />
    <mask
      id="a"
      width={59}
      height={60}
      x={3}
      y={1}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <circle
        cx={32.163}
        cy={30.941}
        r={29.085}
        fill="#00B2F7"
        transform="rotate(-18 32.163 30.941)"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#C5EFFF"
        d="M-4.502 12.273 50.82-5.702l19.418 59.761-55.322 17.976z"
      />
      <mask
        id="b"
        width={47}
        height={56}
        x={11}
        y={8}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
      >
        <path
          fill="#83DC1F"
          d="M14.061 17.224c-2.474 3.131-3.72 5.864-1.378 12.473 2.341 6.61 9.804 30.175 9.804 30.175l20.186 3.04.09.264.22-.217.306.046-.083-.266L57.75 48.414s-7.814-23.45-9.805-30.174c-1.99-6.724-4.604-8.202-8.446-9.281-2.468-.693-10.567.718-11.998 1.183a90.87 90.87 0 0 0-.554.182v-.002l-.043.017c-.277.091-.502.168-.696.234l-.222.075-.223.07c-.197.06-.424.13-.701.219l-.045.012v.002l-.554.178c-1.43.465-8.813 4.084-10.402 6.095Z"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="url(#c)"
          d="M6.375 17.006h44.271V70.5H6.375z"
          transform="rotate(-18 6.375 17.006)"
        />
        <path
          fill="#6EC410"
          d="M14.147 34.652c-1.466-1.85-2.785-7.362-2.875-8.848 11.819-12.042 29.31-13.08 35.395-11.5 2.203 2.544 3.28 8.716 2.875 8.848-17.319-3.069-31.045 6.53-35.395 11.5Z"
        />
      </g>
    </g>
    <path
      fill="#060436"
      d="M15.225 28.588c-.887-2.535-3.011-4.684-6.043-5.73-3.04-1.048-6.351-.766-9.094.527.882 2.544 3.01 4.702 6.05 5.75.59.204 1.19.357 1.795.463-.537.256-1.059.555-1.559.897C3.72 32.31 2.238 34.953 2.06 37.64c2.987.522 6.255-.082 8.91-1.897 2.654-1.815 4.136-4.458 4.314-7.145l-.059-.01Z"
    />
    <circle
      cx={24.412}
      cy={33.346}
      r={1.396}
      fill="#060436"
      transform="rotate(-18 24.412 33.346)"
    />
    <circle
      cx={40.732}
      cy={28.053}
      r={1.397}
      fill="#060436"
      transform="rotate(-18 40.732 28.053)"
    />
    <mask
      id="d"
      width={25}
      height={17}
      x={23}
      y={32}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#2C2A50"
        d="M34.295 37.18c-.85.276-3.172.772-4.935.547-2.204-.281-5.931.167-5.711 3.734.22 3.567 4.82 9.055 14.32 6.145l.218-.071c9.397-3.23 9.892-10.374 7.974-13.389-1.919-3.015-5.198-1.187-6.816.337-1.294 1.218-3.458 2.18-4.307 2.456l-.743.241Z"
      />
    </mask>
    <g mask="url(#d)">
      <path
        fill="#060436"
        d="m22.73 39.06 22.823-7.415 3.967 12.208-22.824 7.417z"
      />
      <path
        fill="#FF74BF"
        d="M27.462 47.851a13.363 13.363 0 0 1 8.283-7.738 13.363 13.363 0 0 1 11.25 1.391 13.363 13.363 0 0 1-8.283 7.739 13.363 13.363 0 0 1-11.25-1.392Z"
      />
      <path
        fill="#F5F5F5"
        d="m44.347 44.293.016.017.035-.05.148-.097a9.359 9.359 0 0 0-.047-.05l2.731-3.973c-1.106-.571-3.546-1.414-4.453-.21-.635.842-.022 2.208.696 3.263-.951-.723-2.17-1.316-3.04-.751-.931.605-.811 2.12-.486 3.331-.638-1.023-1.577-2.053-2.913-1.62-1.393.454-1.502 1.94-1.393 3.176-.439-1.206-1.25-2.61-2.404-2.549-1.057.055-1.7 1.301-2.039 2.466-.03-1.29-.33-2.8-1.357-3.114-1.441-.44-2.92 1.676-3.479 2.789l4.566 1.615a7.115 7.115 0 0 0-.007.054l.139-.007.073.026.004-.03 4.632-.243.014.097 4.778-1.552-.051-.097 3.837-2.491Z"
      />
    </g>
    <path
      fill="#A3E40D"
      d="M16.615 2.955c2.936-.622 9.854 4.405 12.102 7.09l-10.463 4.729c.233-1.147.164-1.425-.91-4.731-.71-2.183-5.55-6.068-.729-7.088Z"
    />
    <path
      fill="#060436"
      d="M13.589 32.9c-1.466-1.854-2.315-7.527-2.406-9.015 11.8-12.048 28.331-12.768 34.413-11.181 2.203 2.55 3.758 8.575 3.352 8.707-17.307-3.085-31.016 6.515-35.359 11.489Z"
    />
    <mask
      id="e"
      width={15}
      height={20}
      x={51}
      y={22}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#83DC1F"
        d="M64.651 26.163c-1.54-1.044-3.957-1.452-4.9-1.223-.812-4.689-2.26-.222-3.25 1.153-1.5.641-5.084 3.409-5.084 3.409l4.02 12.293s3.973-9.785 8.937-13.55c.73-.555 1.036-1.567.277-2.082Z"
      />
    </mask>
    <g mask="url(#e)">
      <path
        fill="#97DF0D"
        d="m50.086 25.566 14.78-4.802 5.421 16.683-14.78 4.802z"
      />
      <path
        fill="#6FD10D"
        d="M58.506 37.004c-4.098-3.62-5.498-7.333-5.686-8.736l-1.734.377L55.89 43.24l2.616-6.235Z"
      />
    </g>
    <mask
      id="f"
      width={17}
      height={14}
      x={4}
      y={40}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#83DC1F"
        d="M4.358 46.317c.627-1.69 2.278-3.37 3.146-3.728-2.045-4.157 1.652-1.467 3.219-.95 1.549-.355 5.955-.225 5.955-.225l3.87 11.984S11.86 47.99 5.796 47.84c-.916-.023-1.757-.664-1.438-1.523Z"
      />
    </mask>
    <g mask="url(#f)">
      <path
        fill="#97DF0D"
        d="m1.416 41.636 13.846-4.499 5.279 16.245-13.847 4.499z"
      />
      <path
        fill="#71D10D"
        d="M15.427 51.368c1.156-5.198.134-8.924-.521-10.137l1.582-.695 4.569 14.246-5.63-3.414Z"
      />
    </g>
    <defs>
      <linearGradient
        id="c"
        x1={43.957}
        x2={38.075}
        y1={65.811}
        y2={29.53}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6ECE0F" />
        <stop offset={1} stopColor="#A2E30C" />
      </linearGradient>
    </defs>
    <mask
      id="a"
      width={59}
      height={60}
      x={3}
      y={1}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <circle
        cx={32.163}
        cy={30.941}
        r={29.085}
        fill="#00B2F7"
        transform="rotate(-18 32.163 30.941)"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#C5EFFF"
        d="M-4.502 12.273 50.82-5.702l19.418 59.761-55.322 17.976z"
      />
      <mask
        id="b"
        width={47}
        height={56}
        x={11}
        y={8}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
      >
        <path
          fill="#83DC1F"
          d="M14.061 17.224c-2.474 3.131-3.72 5.864-1.378 12.473 2.341 6.61 9.804 30.175 9.804 30.175l20.186 3.04.09.264.22-.217.306.046-.083-.266L57.75 48.414s-7.814-23.45-9.805-30.174c-1.99-6.724-4.604-8.202-8.446-9.281-2.468-.693-10.567.718-11.998 1.183a90.87 90.87 0 0 0-.554.182v-.002l-.043.017c-.277.091-.502.168-.696.234l-.222.075-.223.07c-.197.06-.424.13-.701.219l-.045.012v.002l-.554.178c-1.43.465-8.813 4.084-10.402 6.095Z"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="url(#c)"
          d="M6.375 17.006h44.271V70.5H6.375z"
          transform="rotate(-18 6.375 17.006)"
        />
        <path
          fill="#6EC410"
          d="M14.147 34.652c-1.466-1.85-2.785-7.362-2.875-8.848 11.819-12.042 29.31-13.08 35.395-11.5 2.203 2.544 3.28 8.716 2.875 8.848-17.319-3.069-31.045 6.53-35.395 11.5Z"
        />
      </g>
    </g>
    <path
      fill="#060436"
      d="M15.225 28.588c-.887-2.535-3.011-4.684-6.043-5.73-3.04-1.048-6.351-.766-9.094.527.882 2.544 3.01 4.702 6.05 5.75.59.204 1.19.357 1.795.463-.537.256-1.059.555-1.559.897C3.72 32.31 2.238 34.953 2.06 37.64c2.987.522 6.255-.082 8.91-1.897 2.654-1.815 4.136-4.458 4.314-7.145l-.059-.01Z"
    />
    <circle
      cx={24.412}
      cy={33.346}
      r={1.396}
      fill="#060436"
      transform="rotate(-18 24.412 33.346)"
    />
    <circle
      cx={40.732}
      cy={28.053}
      r={1.397}
      fill="#060436"
      transform="rotate(-18 40.732 28.053)"
    />
    <mask
      id="d"
      width={25}
      height={17}
      x={23}
      y={32}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#2C2A50"
        d="M34.295 37.18c-.85.276-3.172.772-4.935.547-2.204-.281-5.931.167-5.711 3.734.22 3.567 4.82 9.055 14.32 6.145l.218-.071c9.397-3.23 9.892-10.374 7.974-13.389-1.919-3.015-5.198-1.187-6.816.337-1.294 1.218-3.458 2.18-4.307 2.456l-.743.241Z"
      />
    </mask>
    <g mask="url(#d)">
      <path
        fill="#060436"
        d="m22.73 39.06 22.823-7.415 3.967 12.208-22.824 7.417z"
      />
      <path
        fill="#FF74BF"
        d="M27.462 47.851a13.363 13.363 0 0 1 8.283-7.738 13.363 13.363 0 0 1 11.25 1.391 13.363 13.363 0 0 1-8.283 7.739 13.363 13.363 0 0 1-11.25-1.392Z"
      />
      <path
        fill="#F5F5F5"
        d="m44.347 44.293.016.017.035-.05.148-.097a9.359 9.359 0 0 0-.047-.05l2.731-3.973c-1.106-.571-3.546-1.414-4.453-.21-.635.842-.022 2.208.696 3.263-.951-.723-2.17-1.316-3.04-.751-.931.605-.811 2.12-.486 3.331-.638-1.023-1.577-2.053-2.913-1.62-1.393.454-1.502 1.94-1.393 3.176-.439-1.206-1.25-2.61-2.404-2.549-1.057.055-1.7 1.301-2.039 2.466-.03-1.29-.33-2.8-1.357-3.114-1.441-.44-2.92 1.676-3.479 2.789l4.566 1.615a7.115 7.115 0 0 0-.007.054l.139-.007.073.026.004-.03 4.632-.243.014.097 4.778-1.552-.051-.097 3.837-2.491Z"
      />
    </g>
    <path
      fill="#A3E40D"
      d="M16.615 2.955c2.936-.622 9.854 4.405 12.102 7.09l-10.463 4.729c.233-1.147.164-1.425-.91-4.731-.71-2.183-5.55-6.068-.729-7.088Z"
    />
    <path
      fill="#060436"
      d="M13.589 32.9c-1.466-1.854-2.315-7.527-2.406-9.015 11.8-12.048 28.331-12.768 34.413-11.181 2.203 2.55 3.758 8.575 3.352 8.707-17.307-3.085-31.016 6.515-35.359 11.489Z"
    />
    <mask
      id="e"
      width={15}
      height={20}
      x={51}
      y={22}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#83DC1F"
        d="M64.651 26.163c-1.54-1.044-3.957-1.452-4.9-1.223-.812-4.689-2.26-.222-3.25 1.153-1.5.641-5.084 3.409-5.084 3.409l4.02 12.293s3.973-9.785 8.937-13.55c.73-.555 1.036-1.567.277-2.082Z"
      />
    </mask>
    <g mask="url(#e)">
      <path
        fill="#97DF0D"
        d="m50.086 25.566 14.78-4.802 5.421 16.683-14.78 4.802z"
      />
      <path
        fill="#6FD10D"
        d="M58.506 37.004c-4.098-3.62-5.498-7.333-5.686-8.736l-1.734.377L55.89 43.24l2.616-6.235Z"
      />
    </g>
    <mask
      id="f"
      width={17}
      height={14}
      x={4}
      y={40}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path
        fill="#83DC1F"
        d="M4.358 46.317c.627-1.69 2.278-3.37 3.146-3.728-2.045-4.157 1.652-1.467 3.219-.95 1.549-.355 5.955-.225 5.955-.225l3.87 11.984S11.86 47.99 5.796 47.84c-.916-.023-1.757-.664-1.438-1.523Z"
      />
    </mask>
    <g mask="url(#f)">
      <path
        fill="#97DF0D"
        d="m1.416 41.636 13.846-4.499 5.279 16.245-13.847 4.499z"
      />
      <path
        fill="#71D10D"
        d="M15.427 51.368c1.156-5.198.134-8.924-.521-10.137l1.582-.695 4.569 14.246-5.63-3.414Z"
      />
    </g>
    <defs>
      <linearGradient
        id="c"
        x1={43.957}
        x2={38.075}
        y1={65.811}
        y2={29.53}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6ECE0F" />
        <stop offset={1} stopColor="#A2E30C" />
      </linearGradient>
    </defs>
  </svg>
);
export default Mojo;
