import * as logClient from "@classdojo/log-client";
import { LoadingMojo } from "@classdojo/web";
import { useEffect } from "react";
import { useNavigate } from "react-router";

export default function Unhandled404() {
  const navigate = useNavigate();

  useEffect(() => {
    const currentLocation = window.location.href;
    const timer = setTimeout(() => {
      logClient.logRequestError("not_found", { method: "GET", url: currentLocation }, {}, []);
      navigate("/");
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate]);

  return <LoadingMojo />;
}
