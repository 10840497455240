import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import { EntityMessageSettingsPayload } from "../types";
import { useTeacherMessageSettingsFetcher } from "./fetchers";

export const useUpdateTeacherMessageSettingsOperation = makeMutation<
  EntityMessageSettingsPayload & { entityId: string },
  void
>({
  name: "useUpdateTeacherMessageSettings",
  fn: async ({ entityId, ...body }) => {
    await callApi({
      method: "PUT",
      path: `/api/teacher/${entityId}/message_settings`,
      body,
    });
  },
  onMutate: (params) => {
    useTeacherMessageSettingsFetcher.setQueriesData((existingState) => ({ ...existingState, ...params }));
  },
  onError: (_data, params) => {
    useTeacherMessageSettingsFetcher.invalidateQueries({ teacherId: params.entityId });
  },
});
