import { MatomoRouterProvider } from "@web-monorepo/shared/utils/matomo";
import { useSessionFetcher } from "app/pods/session";
/**
 * Matomo provider with configuration for teach.
 * It should be included within a Router so it has access to location.
 */
export const MatomoConfiguredProvider = ({ children }: { children: JSX.Element }) => {
  const { data: session } = useSessionFetcher({});
  const parent = session && session.parent;

  return (
    <MatomoRouterProvider userId={parent?._id} urlBase="https://home.classdojo.com" domains={["*.home.classdojo.com"]}>
      {children}
    </MatomoRouterProvider>
  );
};
