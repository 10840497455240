import { makeApiMutation } from "../../reactQuery";
import { useClassMessageThreadsFetcher } from "./fetchers";

export const useCloseThreadOperation = makeApiMutation({
  name: "useCloseThread",
  path: "/api/message-thread/{messageThreadId}/close",
  method: "put",

  onMutate: ({ path: { messageThreadId } }) => {
    useClassMessageThreadsFetcher.setQueriesData((cacheData) => {
      return cacheData.filter((thread) => thread._id !== messageThreadId);
    });
  },
  onError: async (_data) => {
    return await useClassMessageThreadsFetcher.invalidateQueries();
  },
});
