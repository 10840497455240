var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
class SchemaError extends Error {
  constructor(errors) {
    var __super = (...args) => {
      super(...args);
      __publicField(this, "errors");
      return this;
    };
    if (Array.isArray(errors)) {
      __super("value could not be parsed");
      this.errors = errors;
    } else {
      __super(errors);
      this.errors = [errors];
    }
  }
}
function handleError(e, prefix) {
  prefix = prefix ? `${prefix}: ` : "";
  if (e instanceof SchemaError) {
    return e.errors.map((err) => `${prefix}${err}`);
  } else {
    return [`${prefix}${e.message}`];
  }
}

function string() {
  return {
    parse(value) {
      if (typeof value === "string") {
        return value;
      }
      throw new SchemaError(
        `value \`${JSON.stringify(value)}\` is not a string`
      );
    }
  };
}

function number() {
  return {
    parse(value) {
      if (typeof value === "number") {
        return value;
      }
      throw new SchemaError(
        `value \`${JSON.stringify(value)}\` is not a number`
      );
    }
  };
}

function boolean() {
  return {
    parse(value) {
      if (typeof value === "boolean") {
        return value;
      }
      throw new SchemaError(
        `value \`${JSON.stringify(value)}\` is not a boolean`
      );
    }
  };
}

function literal(t) {
  return {
    parse(value) {
      if (value === t) {
        return value;
      }
      throw new SchemaError(
        `value \`${JSON.stringify(value)}\` is not ${JSON.stringify(t)}`
      );
    }
  };
}

function object(definition) {
  return {
    parse(value) {
      const errors = [];
      if (typeof value !== "object" || value == null || Array.isArray(value)) {
        errors.push(`value \`${JSON.stringify(value)}\` is not an object`);
      } else {
        for (const k of Object.keys(definition)) {
          if (!(k in value)) {
            errors.push(`key \`${k}\` is missing`);
          } else {
            try {
              definition[k].parse(value[k]);
            } catch (e) {
              errors.push(...handleError(e, `key \`${k}\``));
            }
          }
        }
      }
      if (errors.length === 0) {
        return value;
      }
      throw new SchemaError(errors);
    }
  };
}

function record(key, value) {
  return {
    parse(input) {
      const errors = [];
      for (const [k, v] of Object.entries(object({}).parse(input))) {
        try {
          key.parse(k);
          value.parse(v);
        } catch (err) {
          errors.push(...handleError(err));
        }
      }
      if (errors.length === 0) {
        return input;
      }
      throw new SchemaError(errors);
    }
  };
}

function array(schema) {
  return {
    parse(value) {
      const errors = [];
      if (!Array.isArray(value)) {
        errors.push(`value \`${JSON.stringify(value)}\` is not an array`);
      } else {
        for (let i = 0; i < value.length; i++) {
          try {
            schema.parse(value[i]);
          } catch (e) {
            errors.push(...handleError(e, `index \`${i}\``));
          }
        }
      }
      if (errors.length === 0) {
        return value;
      }
      throw new SchemaError(errors);
    }
  };
}

function and(parsers) {
  return {
    parse(value) {
      const errors = [];
      for (const parser of parsers) {
        try {
          return parser.parse(value);
        } catch (e) {
          errors.push(...handleError(e));
        }
      }
      if (errors.length === 0) {
        return value;
      }
      throw new SchemaError(errors);
    }
  };
}

function or(parsers) {
  return {
    parse(value) {
      const errors = [];
      for (const parser of parsers) {
        try {
          return parser.parse(value);
        } catch (e) {
          errors.push(...handleError(e));
        }
      }
      if (errors.length === 0) {
        return value;
      }
      throw new SchemaError(errors);
    }
  };
}

export { and, array, boolean, literal, number, object, or, record, string };
