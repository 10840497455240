import { createContext, useContext } from "react";

type LoggingMetadata = {
  entityType: "teacher" | "parent";
};

const CONTEXT = createContext<LoggingMetadata | null>(null);

export const useLoggingMetadata = () => useContext(CONTEXT);
export default CONTEXT;
