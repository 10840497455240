import { errorTypeMaker } from "@web-monorepo/shared/errors/errorTypeMaker";

const errors = {
  api: {
    auth: errorTypeMaker("api-auth"),
    down: errorTypeMaker("api-down"),
    unknown: errorTypeMaker("api-unknown"),
    rateLimit: errorTypeMaker("api-rateLimit"),
    // note: not for all 403s and 404s, just for ones that should redirect you to a special page.
    forbidden: errorTypeMaker("api-forbidden"),
    notFound: errorTypeMaker("api-notFound"),
  },
  login: {
    invalid: errorTypeMaker("login-invalid"),
    lockout: errorTypeMaker("login-lockout"),
    email: errorTypeMaker("login-email"),
    password: errorTypeMaker<{ password: string }>("login-password"),
    code: errorTypeMaker("login-code"),
    parent: errorTypeMaker("login-parent"),
    student: errorTypeMaker("login-student"),
    session: errorTypeMaker("login-session"),
    suspended: errorTypeMaker("login-suspended"),
    forceResetPassword: errorTypeMaker("login-forceResetPassword"),
    mustUseOtc: errorTypeMaker<{ message: string }>("login-mustUseOtc"),
    userCancelledOTPEntry: errorTypeMaker("login-userCancelledOTPEntry"),
  },
  signup: {
    email: errorTypeMaker<{ email: string | undefined; existingAccountType: string | undefined }>("signup-email"),
    insecurePassword: errorTypeMaker("signup-insecurePassword"),
    commonPassword: errorTypeMaker("signup-commonPassword"),
  },
  reset: {
    email: errorTypeMaker("reset-email"),
    expired: errorTypeMaker("reset-expired"),
    cantReset: errorTypeMaker("reset-cantReset"),
    suspended: errorTypeMaker("reset-suspended"),
  },
  parentCode: {
    alreadyRedeemed: errorTypeMaker("parentCode-alreadyRedeemed"),
    notFound: errorTypeMaker("parentCode-notFound"),
  },
  classCode: {
    studentIsNotInClass: errorTypeMaker("classCode-studentIsNotInClass"),
    notFound: errorTypeMaker("classCode-notFound"),
  },
  pdf: {
    timeout: errorTypeMaker("pdf-timeout"),
  },
  parent: {
    emailAlreadyExist: errorTypeMaker("parent-emailAlreadyExist"),
  },
  classroom: {
    notFoundByCode: errorTypeMaker("classroom-notFoundByCode"),
  },
} as const;

export default errors;
