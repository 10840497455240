const serviceWorkerFilename = `asset-retry-sw.js`;
function registerServiceWorker() {
  if ("serviceWorker" in navigator) {
    return navigator.serviceWorker.register(serviceWorkerFilename);
  } else {
    return Promise.reject(new Error("ServiceWorker not supported"));
  }
}
async function unregisterServiceWorker() {
  if ("serviceWorker" in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    return Promise.all(
      registrations.map(
        (registration) => registration.unregister().then((success) => {
          if (!success)
            throw new Error("Failed to unregister service worker");
          return true;
        })
      )
    ).then((m) => {
      if (m.filter((x) => !x).length > 0)
        return true;
      return false;
    });
  } else {
    return false;
  }
}

export { registerServiceWorker, serviceWorkerFilename, unregisterServiceWorker };
