import { useMemo } from "react";
import * as logClient from "@classdojo/log-client";
import { useLoggingMetadata } from "./context";

type LoggableDetails = Record<string, string | number | null | undefined>;

type Args<T extends LoggableDetails = LoggableDetails> = {
  productEventNamespace: string | undefined;
} & T;

const LOCAL_NAMESPACE = "one_time_codes";

type Handler<T extends Args = Args> = (args: T) => void;

const toEventName = (
  eventName: string,
  entityType: "teacher" | "parent",
  { productEventNamespace }: Args,
) => [productEventNamespace, entityType, LOCAL_NAMESPACE, eventName].join(".");

const toHandler =
  <T extends Args = Args>(
    eventName: string,
    entityType: "teacher" | "parent",
    mapper?: (
      args: T,
    ) => Pick<logClient.ProductEvent, "metadata" | "eventValue">,
  ): Handler<T> =>
  (args) => {
    if (args.productEventNamespace) {
      logClient.logEvent({
        ...(mapper?.(args) ?? {}),
        eventName: toEventName(eventName, entityType, args),
      });
    }
  };

const toEventLogger = (entityType: "teacher" | "parent") =>
  ({
    cancelledOTPEntry: toHandler("otp_entry.cancelled", entityType),
    invalidCode: toHandler("invalid_code", entityType),
    validCode: toHandler("valid_code", entityType),
    mfaTriggered: toHandler<Args<{ reason: string }>>(
      "mfa_triggered",
      entityType,
      ({ reason }) => ({
        metadata: {
          reason,
        },
      }),
    ),
  }) satisfies Record<string, Handler<any>>;

export const useEventLogger = (entityType?: "teacher" | "parent") => {
  const metadata = useLoggingMetadata();
  return useMemo(() => {
    if (!entityType && !metadata?.entityType) {
      throw new Error("missing entityType (something is misconfigured)");
    }

    return toEventLogger((entityType ?? metadata?.entityType)!);
  }, [entityType, metadata]);
};
