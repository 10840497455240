import { makeCollectionQuery } from "../reactQuery";

export const useAllClassroomFetcher = makeCollectionQuery({
  fetcherName: "allClassroom",
  path: "/api/dojoClass",
  query: {
    withPending: "true",
    withArchived: "true",
    withRequestedCollaborators: "true",
    skipUnreadNotificationCounts: "true",
  },
});
