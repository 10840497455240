import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import { useMessageThreadFetcher, useParentMessageThreadsFetcher } from "./fetchers";

type BlockParticipantParams = {
  entity: { _id: string; type: "parent" | "teacher" };
  blockedEntity: { _id: string; type: "parent" | "teacher" };
  messageThreadId?: string;
};

export const useBlockParticipantOperation = makeMutation<BlockParticipantParams, void>({
  name: "blockParticipant",
  fn: async ({ entity, blockedEntity }) => {
    const body = { entityId: blockedEntity._id, entityType: blockedEntity.type };
    try {
      const res = await callApi({
        method: "POST",
        path: `/api/${entity.type}/${entity._id}/block`,
        body,
      });

      return res.body;
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        return error;
      }

      throw error;
    }
  },
  onSuccess: (_data, params) => {
    if (params.messageThreadId) {
      useMessageThreadFetcher.invalidateQueries({ messageThreadId: params.messageThreadId });
    }

    if (params.entity.type === "parent") {
      useParentMessageThreadsFetcher.invalidateQueries({ parentId: params.entity._id });
    }
  },
});

type UnblockParticipantParams = {
  entity: { _id: string; type: "parent" | "teacher" };
  blockedEntity: { _id: string; type: "parent" | "teacher" };
  messageThreadId?: string;
};

export const useUnblockParticipantOperation = makeMutation<UnblockParticipantParams, void>({
  name: "parentUnblockParticipant",
  fn: async ({ entity, blockedEntity }) => {
    const body = { entityId: blockedEntity._id, entityType: blockedEntity.type };
    try {
      const res = await callApi({
        method: "DELETE",
        path: `/api/${entity.type}/${entity._id}/block/${blockedEntity._id}`,
        body,
      });

      return res.body;
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        return error;
      }

      throw error;
    }
  },
  onSuccess: (_data, params) => {
    if (params.messageThreadId) {
      useMessageThreadFetcher.invalidateQueries({ messageThreadId: params.messageThreadId });
    }

    if (params.entity.type === "parent") {
      useParentMessageThreadsFetcher.invalidateQueries({ parentId: params.entity._id });
    }
  },
});
