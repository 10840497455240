import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import { EntityMessageSettingsPayload } from "../types";
import { useParentMessageSettingsFetcher } from "./fetchers";

export const useUpdateParentMessageSettingsOperation = makeMutation<
  EntityMessageSettingsPayload & { entityId: string },
  void
>({
  name: "useUpdateParentMessageSettings",
  fn: async ({ entityId, ...body }) => {
    await callApi({
      method: "PUT",
      path: `/api/parent/${entityId}/message_settings`,
      body,
    });
  },
  onSuccess: (_data, params) => {
    useParentMessageSettingsFetcher.invalidateQueries({ parentId: params.entityId });
  },
});
