import { Message } from "@classdojo/web/pods/realtime/adapters/types";
import createAction from "@web-monorepo/infra/createAction";
import createWaitableSaga from "@web-monorepo/infra/createWaitableSaga";
import { refreshThread, getMyLatestMessageStart, closeThread } from "@web-monorepo/shared/messaging/actions";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import type { AnyAction } from "redux";
import { SagaIterator } from "redux-saga";
import { takeEvery, put } from "redux-saga/effects";
import env from "app/utils/env";

const STATE_KEY = "pubnub";

// ACTIONS
export const RECEIVE_MESSAGE = createAction("pubnub/receiveMessage");

// ACTION CREATORS

export const receiveMessage = (command: string, message: Message) => {
  return {
    type: RECEIVE_MESSAGE,
    payload: {
      command,
      message,
    },
  };
};

// SAGAS
function* receiveMessageSaga() {
  yield takeEvery(
    RECEIVE_MESSAGE,

    // eslint-disable-next-line complexity
    createWaitableSaga(function* ({ payload: { command, message } }: AnyAction) {
      const payload = message;

      log("Received message", message);

      switch (command) {
        case "message": {
          const threadId = payload.payload.channelId;

          // thread UI side-effect
          yield put(refreshThread(threadId));
          return;
        }

        // similar to `message`: fetches latest messages in the current thread
        case "refresh-message-thread": {
          const { messageThreadId } = payload.payload;
          yield put(refreshThread(messageThreadId));
          return;
        }

        case "close-message-thread": {
          const { messageThreadId } = payload.payload;
          yield put(closeThread(messageThreadId));
          return;
        }

        // only used for read status
        case "refresh-message":
          yield put(getMyLatestMessageStart(payload.payload.messageThreadId));
          return;

        default:
          log("No handler for command", command);
      }
    } as (...args: unknown[]) => SagaIterator),
  );
}

function log(...args: unknown[]) {
  if ((env.isDev || global.I_REALLY_WANT_PUBNUB_LOGS) && !global.hideLogs) {
    /* eslint-disable-next-line no-console */
    console.info("PUBNUB - ", ...args);
  }
}

const install: PodInstallFunction = (installReducer, installSaga) => {
  installReducer(STATE_KEY, null);

  installSaga(receiveMessageSaga);
};

export default install;
