import { useDebounce } from "@classdojo/web/hooks/useDebounce";
import { useCallback } from "react";
import { makeApiMutation } from "../../reactQuery";
import { queryClient } from "../../reactQuery/queryClient";
import { makeQueryKey } from "../../reactQuery/queryKey";
import { MessageThread } from "../types";
import { useMessageThreadFetcher } from "./fetchers";
import { captureException } from "@web-monorepo/telemetry";

const useMarkMessageThreadReadOperation = makeApiMutation({
  name: "markMessageThreadRead",
  path: "/api/message-thread/{messageThreadId}/markReadOffset",
  method: "post",
  retry: 0,
  onSuccess: (_data, params) => {
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "classMessageThreads" }) });
    useMessageThreadFetcher.setQueriesData(
      (draft) => {
        draft = { ...draft, unreadCount: 0 };
        return draft;
      },
      { messageThreadId: params.path.messageThreadId },
    );
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "parentMessageThreads" }) });

    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "schoolUnreadNotificationCount" }) });
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "staffMessageThreads" }) });
    queryClient.invalidateQueries({
      queryKey: makeQueryKey({ fetcherName: "schoolTeacherUnifiedInboxMessageThreads" }),
    });
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "schoolUnreadMessagesCount" }) });
  },
});

export const useMarkMessageThreadReadWrapperOperation = (thread?: MessageThread) => {
  const debouncedThread = useDebounce(thread, 1000);
  const { mutate: markReadOffset } = useMarkMessageThreadReadOperation();

  const markRead = useCallback(() => {
    if (debouncedThread && debouncedThread.unreadCount > 0 && debouncedThread.lastMessage) {
      markReadOffset(
        {
          path: { messageThreadId: debouncedThread._id },
          body: { beforeMessageId: debouncedThread.lastMessage._id },
        },
        {
          onError: (error: any) => {
            if (error.response && error.response.status >= 500) {
              return error;
            }

            if (error.response && error.response.status === 429) {
              captureException(error);
            }

            throw error;
          },
        },
      );
    }
  }, [markReadOffset, debouncedThread]);

  return markRead;
};
