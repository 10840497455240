import "./SemanticTypography.css";

/**
 * SemanticTypography is the root component for the typography design system. It
 * sets up typography to "just work", with sensible defaults for headings,
 * paragraphs, and links.
 */
export function SemanticTypography({ children }: React.PropsWithChildren) {
  return <div className="ddsText">{children}</div>;
}
