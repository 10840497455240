import {
  type FC,
  type MutableRefObject,
  type PropsWithChildren,
  Component,
  useMemo,
  useRef,
  useState,
} from "react";
import { DDSDialog, SemanticTypography } from "@web-monorepo/dds";
import { UserCancelledCodeEntryError } from "../errors";
import { type Translator, TRANSLATOR_CONTEXT } from "../i18n";
import { LOGGING_CONTEXT, useEventLogger } from "../logging";
import VerificationPage, { type Props as ViewProps } from "../VerificationPage";
import CONTEXT, { type GetCodeParams } from "./context";
import styles from "./OTCPageProvider.module.css";

let pageKey = 0;
type ProviderProps = PropsWithChildren<{
  entityType: "parent" | "teacher";
  translate: Translator;
}>;

const CLOSED_WITH_HANDLED_PROMISE =
  "This dialog was closed by internal handlers (i.e. not by the escape key)";

class StifleRejectionErrors extends Component<PropsWithChildren> {
  static getDerivedStateFromError() {
    return null;
  }

  render() {
    return <>{this.props.children}</>;
  }

  componentDidCatch(error: Error): void {
    if (!(error instanceof UserCancelledCodeEntryError)) {
      throw error;
    }
  }
}

export const OTCPageProvider: FC<ProviderProps> = ({
  children,
  entityType,
  translate,
}) => {
  const [otcPageProps, setOTCPageProps] = useState<ViewProps | null>(null);
  const dialog = useRef() as MutableRefObject<HTMLDialogElement>;
  const logEvent = useEventLogger(entityType);

  const handlers = useMemo(() => {
    const close = () => {
      setOTCPageProps(null);
      if (dialog.current?.open) {
        dialog.current.close(CLOSED_WITH_HANDLED_PROMISE);
      }
    };

    return {
      getCode: (params: GetCodeParams) =>
        new Promise<string>((onCode, onCancel) => {
          pageKey++;
          setOTCPageProps({
            ...params,
            entityType,
            onCode,
            onCancel: () => {
              onCancel(new UserCancelledCodeEntryError(params.error));
              close();
            },
          });
          if (!dialog.current?.open) {
            dialog.current?.showModal();
            dialog.current?.addEventListener("close", () => {
              setOTCPageProps(null);
              if (dialog.current?.returnValue !== CLOSED_WITH_HANDLED_PROMISE) {
                logEvent.cancelledOTPEntry({
                  productEventNamespace: "one_time_codes",
                });
                onCancel(new UserCancelledCodeEntryError(params.error));
              }
            });
          }
        }),
      close,
    };
  }, [entityType, logEvent, setOTCPageProps]);

  return (
    <StifleRejectionErrors>
      <LOGGING_CONTEXT.Provider value={{ entityType }}>
        <CONTEXT.Provider value={handlers}>
          {children}
          <DDSDialog
            data-name="one_time_code"
            ref={dialog}
            className={styles.otcDialog}
          >
            <TRANSLATOR_CONTEXT.Provider value={translate}>
              <SemanticTypography>
                {otcPageProps && (
                  <VerificationPage key={pageKey} {...otcPageProps} />
                )}
              </SemanticTypography>
            </TRANSLATOR_CONTEXT.Provider>
          </DDSDialog>
        </CONTEXT.Provider>
      </LOGGING_CONTEXT.Provider>
    </StifleRejectionErrors>
  );
};
