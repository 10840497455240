import { jsx, Fragment } from 'react/jsx-runtime';
import { useEffect, Suspense } from 'react';
import { initReactI18next, I18nextProvider, useTranslation, Trans } from 'react-i18next';
export { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import MultiReadBackend from 'i18next-multiload-backend-adapter';
import { fetchWithoutCredentials } from '@web-monorepo/infra';
import { Namespaces, LocaleMapping } from '@web-monorepo/vite-auto-translate-plugin/runtime';

const onLocaleChangeHandlers = [];
function emitLocaleChange(locale) {
  onLocaleChangeHandlers.forEach((handler) => handler(locale));
}

const set = (obj, path, value) => {
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  pathArray?.reduce((acc, key, i) => {
    if (acc[key] === void 0)
      acc[key] = {};
    if (i === pathArray.length - 1)
      acc[key] = value;
    return acc[key];
  }, obj);
};
const loadPath = `${Config.bundler === "jest" ? Config.apiEndpoint : ""}/locales/resources.json?lng=__lng__&ns=__ns__&build=${Config.buildNumber || Date.now()}`;
async function loadApiNamespaces(languages, namespaces, callback) {
  try {
    const response = await fetchWithoutCredentials({
      path: loadPath.replace("__lng__", languages.join("+")).replace("__ns__", namespaces.join("+"))
    });
    if (response.ok) {
      const translations = await response.body;
      for (const language in translations) {
        for (const namespace in translations[language]) {
          for (const key in translations[language][namespace]) {
            if (key.includes(":")) {
              const newKey = key.split(":").join(".");
              set(
                translations[language][namespace],
                newKey,
                translations[language][namespace][key]
              );
            }
          }
        }
      }
      callback(null, translations);
    } else {
      callback(new Error("Failed to fetch translations"), null);
    }
  } catch (x) {
    callback(x);
  }
}
function loadLocalNamespace(lng, ns, callback) {
  const file = LocaleMapping[lng]?.[ns];
  if (file) {
    fetchWithoutCredentials({ path: file }).then(({ body }) => {
      callback(null, body);
    }).catch((error) => callback(error));
  } else {
    callback(null, {});
  }
}
const Backend = {
  init() {
  },
  readMulti: async function(languages, namespaces, callback) {
    const localNamespaces = [];
    const apiNamespaces = [];
    for (const ns of namespaces) {
      if (Namespaces.includes(ns)) {
        localNamespaces.push(ns);
      } else {
        apiNamespaces.push(ns);
      }
    }
    if (apiNamespaces.length > 0) {
      loadApiNamespaces(languages, apiNamespaces, callback);
    }
    if (localNamespaces.length > 0) {
      for (const ns of localNamespaces) {
        for (const lng of languages) {
          loadLocalNamespace(lng, ns, (error, body) => {
            if (error)
              callback(error);
            callback(null, { [lng]: { [ns]: body } });
          });
        }
      }
    }
  }
};

const i18n = i18next;
i18n.use(MultiReadBackend);
i18n.use(initReactI18next);
const FALLBACKS = {
  "es-MX": ["es-ES", "en"],
  // Mexican Spanish
  "es-ES": ["es-MX", "en"],
  // Spanish Spanish
  es: ["es-MX", "es-ES", "en"],
  // Generalized spanish
  "fr-CA": ["fr-FR", "en"],
  // Canadian French
  "fr-FR": ["fr-CA", "en"],
  // French French
  "zh-CN": ["zh-HK", "zh-TW", "en"],
  "zh-TW": ["zh-HK", "zh-CN", "en"],
  "zh-HK": ["zh-TW", "zh-CN", "en"],
  ca: ["es-ES", "es-MX", "en"],
  // Catalan
  en: ["en-US"],
  default: ["en"]
};
const SUPPORTED_SPANISH = ["es-MX", "es-ES"];
function mapLocaleToI18nLocale(locale) {
  if (locale === "en-US") {
    return "en";
  }
  if ((locale === "es" || locale.startsWith("es-")) && !SUPPORTED_SPANISH.includes(locale)) {
    return SUPPORTED_SPANISH[0];
  }
  return locale;
}
const i18nOptions = {
  defaultNS: "dojo.common",
  fallbackLng: FALLBACKS,
  debug: false,
  saveMissing: false,
  saveMissingPlurals: false,
  backend: { backend: Backend },
  updateMissing: false,
  // backend: Backend,
  react: { useSuspense: true },
  interpolation: {
    prefix: "__",
    suffix: "__",
    escapeValue: false
    // not needed for react as it escapes by default
  }
};
let namespaces = /* @__PURE__ */ new Set();
function addNamespaces(ns) {
  if (typeof ns === "string")
    namespaces.add(ns);
  else if (Array.isArray(ns))
    ns.forEach((n) => namespaces.add(n));
}
function initialize(options) {
  addNamespaces(options.ns);
  addNamespaces(i18nOptions.ns);
  i18n.init({
    lng: mapLocaleToI18nLocale(navigator.language) || "en",
    ...i18nOptions,
    ...options,
    backend: {
      ...i18nOptions.backend,
      ...options.backend
    },
    ns: [...namespaces.keys()]
  });
}
function loadNamespaces(ns) {
  addNamespaces(ns);
  return i18n.loadNamespaces(ns);
}

const LoadingBlocker = ({
  children,
  namespaces
}) => {
  useTranslation(namespaces);
  return /* @__PURE__ */ jsx(Fragment, { children });
};
const I18nextContainer = ({
  children,
  locale,
  namespaces,
  fallback
}) => {
  useEffect(() => {
    i18n.changeLanguage(locale || void 0).then(
      () => {
        emitLocaleChange(locale || void 0);
      },
      (err) => {
        err = err.filter(
          (errorStr) => !locale || errorStr.includes(locale)
        );
        if (err.length) {
          throw new Error(
            `Failed to load language resources: ${err.join(", ")}`
          );
        }
      }
    );
  }, [locale, namespaces]);
  useEffect(() => {
    loadNamespaces(namespaces);
  }, [namespaces]);
  return /* @__PURE__ */ jsx(I18nextProvider, { i18n, children: /* @__PURE__ */ jsx(Suspense, { fallback, children: /* @__PURE__ */ jsx(LoadingBlocker, { namespaces, children }) }) });
};

const omit = (obj, props) => {
  obj = { ...obj };
  props.forEach((prop) => delete obj[prop]);
  return obj;
};
const T = (props) => {
  const { t } = useTranslation();
  if ("components" in props && props.components) {
    return /* @__PURE__ */ jsx(
      Trans,
      {
        i18nKey: props.str,
        defaults: props.fallback,
        values: props.subs,
        components: props.components
      }
    );
  }
  const spanProps = omit(props, ["htmlStr", "str", "subs", "fallback"]);
  if (props.htmlStr) {
    const translation = t(props.htmlStr, props.fallback || props.htmlStr);
    return /* @__PURE__ */ jsx("span", { ...spanProps, dangerouslySetInnerHTML: { __html: translation } });
  }
  if (!props.str)
    throw new Error("str not specified");
  return /* @__PURE__ */ jsx("span", { ...spanProps, children: t(
    props.str,
    props.fallback || props.str,
    "subs" in props && props.subs || {}
  ) });
};

function translate(...args) {
  const keyOrObject = args[0];
  if (typeof keyOrObject === "string") {
    if (typeof args[1] === "string") {
      const possibleSubstitutions = args[2];
      if (possibleSubstitutions && typeof possibleSubstitutions === "object") {
        const translated = i18n.t(keyOrObject, args[1], {
          ...possibleSubstitutions,
          returnDetails: false
        });
        if (typeof translated !== "string") {
          throw new Error("Unexpected details object from i18n.t");
        }
        return translated;
      }
      return i18n.t(keyOrObject, args[1]) || args[1];
    }
    return i18n.t(...args) || "";
  }
  if (typeof keyOrObject === "object") {
    const { str, htmlStr, fallback, subs } = keyOrObject;
    const res = i18n.t(str || htmlStr, fallback, {
      ...subs,
      returnDetails: false
    });
    if (typeof res === "object") {
      throw new Error("Translation object returned");
    }
    return res;
  }
  return "";
}

function useLocale() {
  const { i18n } = useTranslation();
  return i18n.language;
}

export { I18nextContainer, T, i18n, initialize, translate, useLocale };
