import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import type { Reducer } from "redux";
import type { Saga } from "redux-saga";
import { ErrorHandler, ERROR_CATCH_TYPE, ErrorHandlerContext } from "./ErrorHandler";
import { triggerSagaError, triggerSagaErrorSaga, triggerReducerError, triggerReducerErrorHandler } from "./testHelpers";

const STATE_KEY = "error";

export default function install(
  installReducer: (stateKey: string, reducer: Reducer) => void,
  installSaga: (saga: Saga) => void,
) {
  installReducer(STATE_KEY, combineActionHandlers(null, [triggerReducerErrorHandler]));
  installSaga(triggerSagaErrorSaga);
}

export { triggerReducerError, triggerSagaError };
export { ErrorHandler, ERROR_CATCH_TYPE, ErrorHandlerContext };
