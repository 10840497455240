import { ChangeEvent, type FC, useCallback, useState } from "react";
import { isPresentableError } from "../../../errors";
import styles from "./OTCInput.module.css";

type DigitProps = {
  index: number;
  isInitialInvalid: boolean;
  totalCharacters: number;
  value: string | undefined;
};

const ZERO_WIDTH_SPACE = "\u200B";

const DigitCell: FC<DigitProps> = ({
  index,
  isInitialInvalid,
  totalCharacters,
  value,
}) => {
  const isCurrent = index === totalCharacters;
  const isValid = Number.isInteger(Number(value));
  const isInvalid = !isValid && typeof value === "string";
  const isEmpty = !value;

  const className = [
    styles.digit,
    isCurrent && styles.current,
    isValid && styles.valid,
    isInvalid && styles.invalid,
    isEmpty && styles.empty,
    isInitialInvalid && styles.initialInvalid,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <span aria-hidden className={className}>
      {value ?? ZERO_WIDTH_SPACE}
    </span>
  );
};

type Props = {
  formError: unknown;
  labeledBy: string;
  onChange(code: string): void;
};

const OTCInput: FC<Props> = ({
  formError,
  labeledBy,
  onChange: parentOnChange,
}) => {
  const [value, setValue] = useState("");
  const formHasError = isPresentableError(formError);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      parentOnChange(event.target.value);
    },
    [setValue, parentOnChange]
  );

  return (
    <label className={styles.container}>
      <input
        autoFocus
        className={styles.input}
        type="text"
        value={value}
        onChange={onChange}
        maxLength={6}
        pattern="\d{6}"
        name="code"
        aria-labelledby={labeledBy}
        autoComplete="one-time-code"
      />
      {Array.from({ length: 6 }, (_, i) => (
        <DigitCell
          isInitialInvalid={formHasError}
          index={i}
          key={i}
          totalCharacters={value.length}
          value={value[i]}
        />
      ))}
    </label>
  );
};

export default OTCInput;
