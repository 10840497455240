import EventEmitter from "events";
import DojoMetrics from "@classdojo/dojo-metrics";
import PubNub from "pubnub";
import { Adapter, Message, MessageHandler } from "./types";

const PUBLISH_KEY = "pub-805cbe07-4cf6-4598-b7a7-99b375929aab";
const SUBSCRIBE_KEY = "sub-e0704dc5-1c84-11e1-b64a-bde5992449da";

export type LogEvent = (e: { eventName: string; eventValue?: string }) => void;

export type LogRequestError = (
  type: string,
  request: Record<string, unknown>,
  response: Record<string, unknown>,
  history: string[],
) => Promise<void>;

type Listener = {
  message?: (m: {
    actualChannel: string;
    channel: string;
    message: Message;
    publisher: string;
    subscribedChannel: string;
    subscription: string;
    timetoken: string;
  }) => void;
  status?: (m: { error?: Error; operation: string; category: string }) => void;
};

export interface Config {
  publishKey?: string;
  subscribeKey?: string;
  logEvent?: LogEvent;
  metrics?: DojoMetrics;
  userId?: string;
}

const KNOWN_MESSAGE_TYPES = [
  "attendancechanged",
  "display_open",
  "display_close",
  "message",
  "notification",
  "protocol::ping",
  "protocol::pong",
  "protocol::enter",
  "protocol::depart",
  "refresh:students",
  "refresh:session",
  "refresh:pending_posts",
  "refresh:behaviors",
  "resetbubbles",
  "reward",
  "random",
  "send_radio",
  "send_ideas",
  "send_noise",
  "share_over_wire_send",
  "send_microphone_permission_error",
  "unfocus",
  "undoaward",
  "refresh:mv_online_now", // refresh dojo islands online player status
  "added_to_island", // Refresh islands membership
];

export default class PubnubAdapter implements Adapter {
  private _closed: boolean;
  private _attemptedSubscribe: boolean;
  private _pubnub: PubNub;
  private _emitter: EventEmitter;
  private _listener: Listener;
  private _metrics?: DojoMetrics;
  private _logEvent?: LogEvent;

  constructor(config: Config = {}) {
    const { publishKey, subscribeKey, logEvent, userId } = config;

    this._pubnub = new PubNub({
      publishKey: publishKey || PUBLISH_KEY,
      subscribeKey: subscribeKey || SUBSCRIBE_KEY,
      ssl: true,
      origin: "classdojo.pubnub.com",
      restore: true,
      userId: userId ?? "unknown",
      autoNetworkDetection: true,
    });
    this._emitter = new EventEmitter();

    this._listener = {
      message: ({ channel, message }) => {
        if (this._metrics) {
          this._metrics.increment("pubnub.receive", { messageType: getAllowedMessageType(message) });
        }
        this._emitter.emit(channel, message, channel);
      },
      status: ({ error, operation = "unknownOperation", category = "unknownCategory" }) => {
        if (error && logEvent && !this._closed && this._attemptedSubscribe) {
          const eventName = `pubnub.${operation}.${category}`;

          if (eventName === "pubnub.PNSubscribeOperation.PNNetworkIssuesCategory" && this._metrics) {
            this._metrics.increment("pubnub.PNSubscribeOperation.PNNetworkIssuesCategory");
          } else {
            logEvent({ eventName });
          }
        }
      },
    };

    this._metrics = config.metrics;

    this._pubnub.addListener(this._listener);
    this._logEvent = logEvent;
  }

  async publish(channel: string, message: Message) {
    if (this._metrics) {
      this._metrics.increment("pubnub.send", { messageType: getAllowedMessageType(message) });
    }

    return new Promise<void>((resolve, reject) => {
      this._pubnub.publish(
        {
          channel,
          message,
        },
        async (status, response) => {
          if (status.error) {
            const errMsg = `${JSON.stringify(status)}: response -- ${response}`;

            if (this._logEvent) {
              this._logEvent({ eventName: "pubnub.publish.error", eventValue: errMsg });
            }

            if (this._metrics) {
              this._metrics.increment("pubnub.publish.fail", {
                messageType: getAllowedMessageType(message),
                pubnubCategory: status?.category || "unknown",
              });
            }

            return reject(new PubnubError(errMsg));
          }
          return resolve();
        },
      );
    });
  }

  subscribe(channel: string | string[], onMessage: MessageHandler, timetoken?: number) {
    this._attemptedSubscribe = true;

    const channels = Array.isArray(channel) ? channel : [channel];

    // update event emitter handlers
    const newMessageHandlerChannels = channels.filter(
      (channel) => !this._emitter.listeners(channel).includes(onMessage),
    );
    newMessageHandlerChannels.forEach((channel) => this._emitter.on(channel, onMessage));

    // update pubnub channel subscriptions
    const pubnubChannels = this._pubnub.getSubscribedChannels();
    const pubnubChannelsWithoutSubscription = channels.filter((channel) => !pubnubChannels.includes(channel));
    if (pubnubChannelsWithoutSubscription.length > 0) {
      this._pubnub.subscribe({ channels: pubnubChannelsWithoutSubscription, timetoken });
    }
  }

  unsubscribe(channel: string | string[], onMessage: MessageHandler) {
    const channels = Array.isArray(channel) ? channel : [channel];

    // update event emitter handlers
    channels.forEach((channel) => this._emitter.removeListener(channel, onMessage));

    // update pubnub channel subscriptions
    const channelsWithoutListeners = channels.filter((channel) => this._emitter.listenerCount(channel) === 0);
    const pubnubChannels = this._pubnub.getSubscribedChannels();
    const pubnubChannelsWithoutListeners = channelsWithoutListeners.filter((channel) =>
      pubnubChannels.includes(channel),
    );
    if (pubnubChannelsWithoutListeners.length > 0) {
      // no remaining listeners, so tell pubnub to unsubscribe
      this._pubnub.unsubscribe({ channels: pubnubChannelsWithoutListeners });
    }
  }

  close() {
    this._closed = true;
    this._pubnub.removeListener(this._listener);
    this._pubnub.stop();
  }
}

export class PubnubError extends Error {
  isPubnubError = true;
  constructor(message: string) {
    super(message);
  }
}

//
//
// Extra debugging utils
//
//
function getMessageType(message: Message) {
  return (message && (message.command || message.action || message.topic)) || "";
}
function getAllowedMessageType(message: Message): string {
  const messageType = getMessageType(message);
  if (KNOWN_MESSAGE_TYPES.includes(messageType?.toLowerCase())) return messageType;
  return "unknown";
}
