import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import { useScheduledMessagesFetcher, useMessageThreadFetcher, useMessagesFetcher } from "./fetchers";
import { queryClient } from "../../reactQuery/queryClient";
import { makeQueryKey } from "../../reactQuery/queryKey";

type DeleteThreadMessageParams = {
  threadId: string;
  messageId: string;
};

export const useDeleteThreadMessageOperation = makeMutation<DeleteThreadMessageParams, void>({
  name: "deleteThreadMessage",
  fn: async ({ threadId, messageId }) => {
    await callApi({
      method: "DELETE",
      path: `/api/message-thread/${threadId}/message/${messageId}`,
    });
  },
  onSuccess: (_data, params) => {
    useScheduledMessagesFetcher.setQueriesData((draft) => {
      return draft.filter((message) => message._id !== params.messageId);
    });
    useMessageThreadFetcher.invalidateQueries({ messageThreadId: params.threadId });
    useMessagesFetcher.setQueriesData((draft) => {
      return draft.filter((message) => message._id !== params.messageId);
    });
    queryClient.invalidateQueries({
      queryKey: makeQueryKey({ fetcherName: "schoolTeacherUnifiedInboxMessageThreads" }),
    });
  },
});
