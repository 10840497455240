import { take } from "redux-saga/effects";

type SaveMessageDraftAction = { payload: { targetId: string; body: string } } | undefined;

type LocalStorageWrapper = {
  setItem: (key: string, value: string) => void;
  getItem: (key: string) => void;
  removeItem: (key: string) => void;
};

export function createSaveMessageDraftSaga(
  actionType: string,
  localStorage: LocalStorageWrapper,
  prefix = "message_draft",
) {
  return function* saveMessageDraftSaga() {
    while (true) {
      const action: SaveMessageDraftAction = yield take(actionType);

      if (!action) {
        continue;
      }

      const { targetId, body } = action.payload;
      const key = `${prefix}.${targetId}`;
      localStorage?.setItem(key, body);
    }
  };
}
