import * as logClient from "@classdojo/log-client";
import * as Components from "@classdojo/web";
import { logClick } from "@web-monorepo/shared/events/clickLogging";
import { EventRecorder } from "app/pods/devTools";
import deployGroup from "app/utils/deployGroup";
import env from "app/utils/env";
import { translate } from "app/utils/translate";

Components.initialize({
  translate,
  logEvent: (...args) => logClient.logEvent(...args),
});

if ((env.isProd && typeof window !== "undefined") || env.isCypress) {
  window.appVersion = Config.appVersion;
  window.build = Config.buildNumber;

  logClient.init({
    env: env.isProd ? "prod" : "testing",
    deployGroup,
    site: "home",
    customEndpoint: "/logs",
    buildNumber: Config.buildNumber,
    appVersion: Config.appVersion,
  });
} else if (env.isDev) {
  logClient.init({ env: "dev", site: "home" });
} else if (env.isTesting) {
  logClient.init({
    env: "testing",
    customEndpoint: "/logs",
    site: "home",
    disabledForTest: true,
  });
}

window.addEventListener("unload", logClient.flush.bind(logClient));

EventRecorder.start();

document.body.addEventListener("click", (ev) => logClick(ev, "home"), { capture: true });
