import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import { queryClient } from "../../reactQuery/queryClient";
import { makeQueryKey } from "../../reactQuery/queryKey";
import { MessageDetailsWithThreadId, MessageDetails } from "../types";
import { Message, MessageBatchItem } from "../types";
import { useMessageThreadFetcher, useScheduledMessagesFetcher, useMessagesFetcher } from "./fetchers";

export const useSendThreadMessageOperation = makeMutation<MessageDetailsWithThreadId & { language: string }, Message>({
  name: "sendThreadMessage",
  networkMode: "always", // forces mutation even when offline. use onErrors to catch
  fn: async ({ messageThreadId, text, attachments, scheduledDate, urgent, language }) => {
    const scheduleFor = scheduledDate ? scheduledDate.toISOString() : undefined;
    const body = { messageThreadId, body: text, attachments, scheduleFor, urgent };

    try {
      const res = await callApi({
        method: "POST",
        path: `/api/message-thread/${messageThreadId}/message/v2`,
        body,
        headers: {
          "Accept-Language": language,
        },
      });
      return res.body;
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        return error;
      }

      if (error.response && error.response.status === 400) {
        useMessageThreadFetcher.invalidateQueries({ messageThreadId });
        return error;
      }

      return error;
    }
  },
  onSuccess: (data, params) => {
    useScheduledMessagesFetcher.invalidateQueries({ messageThreadId: params.messageThreadId });
    useMessageThreadFetcher.invalidateQueries({ messageThreadId: params.messageThreadId });
    useMessagesFetcher.setQueriesData(
      (old) => {
        // Add handler for replacing failed message with successful message.
        if (data && data.scheduled === false) old.push(data);
      },
      { messageThreadId: params.messageThreadId },
    );
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "classMessageThreads" }) });
    // Not a great place to do this but it works to resort threads for staff messaging
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "staffMessageThreads" }) });
    queryClient.invalidateQueries({
      queryKey: makeQueryKey({ fetcherName: "schoolTeacherUnifiedInboxMessageThreads" }),
    });
  },
});

type MessageBatchOperationPayload = {
  message: MessageDetails;
  messageThreadIds: string[];
  language: string;
};

export const useSendThreadMessageBatchOperation = makeMutation<MessageBatchOperationPayload, MessageBatchItem[]>({
  name: "sendThreadMessageBatch",
  fn: async ({ messageThreadIds, message, language }) => {
    const { text, attachments, scheduledDate, urgent } = message;
    const scheduleFor = scheduledDate ? scheduledDate.toISOString() : undefined;
    const body = { messageThreadIds, body: text, attachments, scheduleFor, urgent };

    try {
      const res = await callApi({
        method: "POST",
        path: `/api/message-thread/message-batch`,
        body,
        headers: {
          "Accept-Language": language,
        },
      });
      return res.body._items;
    } catch (error) {
      if (error.response && error.response.status >= 500) {
        return error;
      }

      throw error;
    }
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "classMessageThreads" }) });
  },
});

export const useSendThreadMessageSequenceOperation = makeMutation<
  Array<MessageDetailsWithThreadId & { language: string }>,
  Message | void
>({
  name: "sendThreadMessageSequence",
  fn: async (messages) => {
    for (const { messageThreadId, text, attachments, scheduledDate, urgent, language } of messages) {
      const scheduleFor = scheduledDate ? scheduledDate.toISOString() : undefined;
      // Notice: there is no error handling here. If one api call fails,
      // it will blow up, and not send the subsequent ones. Should be revisted.
      await callApi({
        method: "POST",
        path: `/api/message-thread/${messageThreadId}/message/v2`,
        body: {
          messageThreadId,
          body: text,
          attachments,
          scheduleFor,
          urgent,
        },
        headers: {
          "Accept-Language": language,
        },
      });
    }
  },
});
