import createAction from "@web-monorepo/infra/createAction";
import createActionCreator from "@web-monorepo/infra/createActionCreator";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import { take } from "redux-saga/effects";

/*
  Exports errors that we can use to test what happens when sagas
  or reducers error. They are not added to the app in production.
*/

const TRIGGER_REDUCER_ERROR = createAction("error/triggerReducerError");
const TRIGGER_SAGA_ERROR = createAction("error/triggerSagaError");

export const triggerSagaError = createActionCreator(TRIGGER_SAGA_ERROR);
export const triggerReducerError = createActionCreator(TRIGGER_REDUCER_ERROR);

export function* triggerSagaErrorSaga() {
  while (true) {
    yield take(TRIGGER_SAGA_ERROR);
    throw new Error("saga error");
  }
}

export const triggerReducerErrorHandler = createActionHandler(TRIGGER_REDUCER_ERROR, () => {
  throw new Error("reducer error");
});
