// TODO: We want to remove this once we move away from the redux error handling
import error from "@classdojo/web/pods/errorHandling";
import i18nPodInstaller from "@classdojo/web/pods/i18n";
import threadMessaging from "@web-monorepo/shared/messaging/actions";
import messaging from "app/pods/messaging";
import mobileBlocker from "app/pods/mobileBlocker";
import pubnub from "app/pods/pubnub";
import signup from "app/pods/signup";
import userConfigPodInstaller from "app/pods/userConfig";

export default [
  userConfigPodInstaller,
  i18nPodInstaller,
  error,
  messaging,
  threadMessaging,
  mobileBlocker,
  signup,
  pubnub,
];
