import type { AnyAction } from "redux";

type Handler = (state: unknown, action: AnyAction) => unknown;

/*
  Make a reducer out of a series of action handlers. Each action handler should
  be a function that takes the state and an action and returns either a new state
  or undefined. These will be composed and applied to the full state object.

  See `createActionHandler` in the same directory.
*/
export default function combineActionHandlers(initialState: unknown, handlers: Handler[]) {
  return function reducer(state = initialState, action: AnyAction) {
    return handlers.reduce((s, handler) => handler(s, action), state);
  };
}
