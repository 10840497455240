import { mapLocaleToI18nLocale } from "@classdojo/web/pods/i18n";
import { useMetadataKey } from "../userConfig";
import { useLocalStorage } from "@web-monorepo/safe-browser-storage";
import { useCallback } from "react";
import * as z from "@web-monorepo/parser";

const parse = z.or([z.string(), z.literal(undefined)]).parse;

// Requests to messaging and story endpoints depend on the translation language
// (for the Accept-Language header), so we story this value both in metatata (so
// it syncs across clients) and also in localStorage (so dependent requests don't
// have to wait for metadata to load).
export default function useTranslationLanguage(entityId: string) {
  const locale = navigator.languages?.[0] ?? navigator.language ?? "en";
  const deviceLanguage = mapLocaleToI18nLocale(locale);
  const fallbackLanguage = translationLanguages.find((l) => l.value === deviceLanguage)?.value ?? "en";

  const [localValue, setLocalValue] = useLocalStorage(`entity-${entityId}-translation-language`, fallbackLanguage);
  const metadata = useMetadataKey({ key: "contentLanguageCode", parse });

  const setValue = useCallback(
    (newValue: string) => {
      if (!metadata.isLoading) {
        setLocalValue(newValue);
        metadata.setValue(newValue);
      } else {
        throw new Error("cannot save before user metadata has loaded");
      }
    },
    [metadata, setLocalValue],
  );

  return {
    isLoadingTranslationLanguage: metadata.isLoading,
    translationLanguages,
    translationLanguage: metadata.isLoading ? localValue : (metadata.value ?? localValue),
    setTranslationLanguage: setValue,
  };
}

export const CONTENT_TRANSLATION_LANGUAGE = {
  af: "Afrikaans",
  sq: "Shqip",
  am: "አማርኛ",
  ar: "العربية",
  hy: "Հայերեն",
  as: "অসমীয়া",
  ay: "Aymar aru",
  az: "Azərbaycan dili",
  bm: "Bamanankan",
  eu: "Euskara",
  be: "Беларуская",
  bn: "বাংলা",
  bho: "भोजपुरी",
  bs: "Bosanski",
  bg: "Български",
  ca: "Català",
  ceb: "Sinugboanong Binisaya",
  "zh-CN": "中文 (简体)",
  "zh-TW": "中文 (繁體)",
  co: "Corsu",
  hr: "Hrvatski",
  cs: "Čeština",
  da: "Dansk",
  dv: "ދިވެހި",
  doi: "डोगरी",
  nl: "Nederlands",
  en: "English",
  eo: "Esperanto",
  et: "Eesti",
  ee: "Eʋegbe",
  fil: "Filipino",
  fi: "Suomi",
  fr: "Français",
  fy: "Frysk",
  gl: "Galego",
  ka: "ქართული",
  de: "Deutsch",
  el: "Ελληνικά",
  gn: "Avañe'ẽ",
  gu: "ગુજરાતી",
  ht: "Kreyòl Ayisyen",
  ha: "هَوُسَ",
  haw: "ʻŌlelo Hawaiʻi",
  he: "עברית",
  hi: "हिन्दी",
  hmn: "Hmoob",
  hu: "Magyar",
  is: "Íslenska",
  ig: "Igbo",
  ilo: "Ilokano",
  id: "Bahasa Indonesia",
  ga: "Gaeilge",
  it: "Italiano",
  ja: "日本語",
  jv: "Basa Jawa",
  kn: "ಕನ್ನಡ",
  kk: "Қазақ тілі",
  km: "ភាសាខ្មែរ",
  rw: "Kinyarwanda",
  gom: "कोंकणी",
  ko: "한국어",
  kri: "Krio",
  ku: "Kurdî",
  ckb: "کوردیی ناوەندی",
  ky: "Кыргызча",
  lo: "ລາວ",
  la: "Latina",
  lv: "Latviešu",
  ln: "Lingála",
  lt: "Lietuvių",
  lg: "Luganda",
  lb: "Lëtzebuergesch",
  mk: "Македонски",
  mai: "मैथिली",
  mg: "Malagasy",
  ms: "Bahasa Melayu",
  ml: "മലയാളം",
  mt: "Malti",
  mi: "Māori",
  mr: "मराठी",
  "mni-Mtei": "ꯃꯤꯇꯩ ꯃꯌꯥꯏ",
  lus: "Mizo ţawng",
  mn: "Монгол",
  my: "မြန်မာစာ",
  ne: "नेपाली",
  no: "Norsk",
  ny: "Chi-Chewa",
  or: "ଓଡ଼ିଆ",
  om: "Afaan Oromoo",
  ps: "پښتو",
  fa: "فارسی",
  pl: "Polski",
  pt: "Português",
  pa: "ਪੰਜਾਬੀ",
  qu: "Runa Simi",
  ro: "Română",
  ru: "Русский",
  sm: "Gagana Samoa",
  sa: "संस्कृतम्",
  gd: "Gàidhlig",
  nso: "Sesotho sa Leboa",
  sr: "Српски",
  st: "Sesotho",
  sn: "ChiShona",
  sd: "سنڌي",
  si: "සිංහල",
  sk: "Slovenčina",
  sl: "Slovenščina",
  so: "Soomaali",
  es: "Español",
  su: "Basa Sunda",
  sw: "Kiswahili",
  sv: "Svenska",
  tl: "Filipino",
  tg: "тоҷикӣ",
  ta: "தமிழ்",
  tt: "татарча",
  te: "తెలుగు",
  th: "ไทย",
  ti: "ትግርኛ",
  ts: "Xitsonga",
  tr: "Türkçe",
  tk: "Türkmençe",
  ak: "Akan",
  uk: "Українська",
  ur: "Urdu",
  ug: "Uyghur",
  uz: "Uzbek",
  vi: "Vietnamese",
  cy: "Welsh",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  zu: "Zulu",
};

const translationLanguages = Object.entries(CONTENT_TRANSLATION_LANGUAGE).map(([value, label]) => ({ value, label }));
