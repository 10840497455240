import { LoadingMojo } from "@classdojo/web";
import { initialize, I18nextContainer } from "@web-monorepo/i18next";

const BUNDLES_TO_LOAD = ["dojo.common", "dojo.parent_web", "dojo.beyond"];

type I18NContainerHomeProps = React.PropsWithChildren<{
  locale: string | null;
}>;

initialize({ ns: BUNDLES_TO_LOAD });

const I18NContainerHome = ({ locale, children }: I18NContainerHomeProps) => {
  return (
    <I18nextContainer locale={locale || navigator.language} namespaces={BUNDLES_TO_LOAD} fallback={<LoadingMojo />}>
      {children}
    </I18nextContainer>
  );
};

export default I18NContainerHome;
