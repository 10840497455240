import type { paths } from "@classdojo/ts-api-types";
import {
  type OperationParams,
  type OperationResult,
  makeApiMutation,
  useMutation,
  UseMutationOptions,
} from "@web-monorepo/shared/reactQuery";
import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import { useOTCHandlers } from "./OTCPageProvider";
import { getErrorCode, isHandledError, isInvalidCode } from "./errors";
import { useEventLogger } from "./logging";

type AnyPath = keyof paths;
type MethodForPath<Path extends AnyPath> = keyof paths[Path] & string;

type APIMutation<
  Path extends AnyPath,
  Method extends MethodForPath<Path>,
> = ReturnType<typeof makeApiMutation<Path, Method>>;

type Options<Path extends AnyPath, Method extends MethodForPath<Path>> = {
  /**
   * Allows consumer to provide an optional additional check that must pass before one-time code screen is presented.
   *
   * @param request the request object
   * @returns `true` if the error should be handled by this package
   */
  guard?: (
    request: OperationParams<Path, Method>,
    error: APIResponseError,
  ) => boolean;
  mfaKeyName?: string;
  productEventNamespace?: string;
};

const defaultGuard = () => true;

export const withOTCHandling =
  <
    Path extends AnyPath,
    Method extends MethodForPath<Path>,
    E extends Error = Error,
  >(
    useOriginalMutation: APIMutation<Path, Method>,
    {
      guard = defaultGuard,
      mfaKeyName = "code",
      productEventNamespace,
    }: Options<Path, Method> = {},
  ) =>
  (
    options: UseMutationOptions<
      OperationResult<Path, Method>,
      E,
      OperationParams<Path, Method>
    > = {},
  ): ReturnType<APIMutation<Path, Method>> => {
    const { getCode, close } = useOTCHandlers();
    const { mutateAsync } = useOriginalMutation({ useErrorBoundary: false });
    const logEvent = useEventLogger();

    let extraArgs = {};
    return useMutation({
      ...options,
      retry: (_, error) => isHandledError(error),
      mutationFn: async (variables: OperationParams<Path, Method>) => {
        try {
          const newBody = Object.assign({}, (variables as any).body, extraArgs);
          const result = await mutateAsync(
            Object.assign({}, variables, { body: newBody }),
          );

          // only log this if it was successful because of a code
          if (mfaKeyName in extraArgs) {
            logEvent.validCode({ productEventNamespace });
          }

          close();
          return result;
        } catch (error) {
          if (
            isHandledError(error) &&
            "body" in variables &&
            guard(variables, error)
          ) {
            if (productEventNamespace && isInvalidCode(error)) {
              logEvent.invalidCode({ productEventNamespace });
            } else if (productEventNamespace) {
              logEvent.mfaTriggered({
                reason: getErrorCode(error),
                productEventNamespace,
              });
            }

            const code = await getCode({ error });
            extraArgs = { [mfaKeyName]: code };
          } else {
            close(); // make sure the dialog is closed
          }

          throw error;
        }
      },
    }) as ReturnType<APIMutation<Path, Method>>;
  };
