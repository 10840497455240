import * as logClient from "@classdojo/log-client";
import { DevToolsSettings } from "app/pods/devTools";
import { setFeatureSwitchesConfig } from "app/pods/featureSwitches";
import {
  PARENT_FEATURE_SWITCHES,
  SCHOOL_FEATURE_SWITCHES,
  SESSION_FEATURE_SWITCHES,
} from "app/pods/featureSwitches/constants";
import { useFetchedParent } from "app/pods/parent";
import { useSessionFetcher } from "app/pods/session";
export { FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";

type QueryStringParams = {
  parentId?: string;
  parentLocale?: string;
  parentCountryCode?: string;
  parentSwitches?: string;
  sessionId?: string;
  sessionSwitches?: string;
  [key: string]: string | undefined;
};

// This hook is called when loading the feature switches and it returns the query params object
// for the feature switch parameters to be sent to the API.
// If no parameter values are available yet, you can return `null` to delay the API call until
// they become available in a later render cycle.
const useBuildFeatureSwitchesQueryParamsHook = () => {
  const { data: session, error: sessionError } = useSessionFetcher({});
  const sessionId = logClient.getSessionId();
  const parent = useFetchedParent();

  // Session is our only dependency, wait for it to request the switches
  if (!session && !sessionError) return null;

  const queryStringParams: QueryStringParams = {};

  if (Object.values(PARENT_FEATURE_SWITCHES).length && session && session.parent) {
    // only load feature switches associated with parent if user is logged in
    queryStringParams.parentId = session.parent._id;
    queryStringParams.parentLocale = session.parent.locale || "en-US";
    queryStringParams.parentSwitches = encodeURIComponent(Object.values(PARENT_FEATURE_SWITCHES).join(","));
  }

  if (
    Object.values(SCHOOL_FEATURE_SWITCHES).length &&
    session &&
    session.parent &&
    session.parent.schoolIds.length > 0
  ) {
    if (session.parent.schoolIds.length > 10) {
      logClient.logException(
        new Error(
          `Parent has more than 10 schools associated with them.
          This is not supported and may cause feature switch issues.`,
        ),
        {
          parentId: session.parent._id,
          schoolIds: session.parent.schoolIds,
        },
      );
    }

    for (const [idx, schoolId] of parent.schoolIds.entries()) {
      queryStringParams[`school${idx}Id`] = schoolId;
      queryStringParams[`school${idx}Switches`] = encodeURIComponent(Object.values(SCHOOL_FEATURE_SWITCHES).join(","));
    }
  }

  // We choose 'session' as the name for the group when reaching the case of a logged out user.
  // Session id always will exist, either the session id coming from the cookie (resolved by the log client)
  // or passed as argument from external site (and set using log client).
  if (Object.values(SESSION_FEATURE_SWITCHES).length && sessionId) {
    queryStringParams.sessionId = sessionId;
    queryStringParams.sessionSwitches = encodeURIComponent(Object.values(SESSION_FEATURE_SWITCHES).join(","));
  }

  return queryStringParams;
};

export function setAppFeatureSwitchesConfig() {
  setFeatureSwitchesConfig({
    featureSetIdLocalStorageKey: `home/featureSwitches/featureSetId`,
    useBuildFeatureSwitchesQueryParamsHook,
    useFeatureSwitchOverridesHook: () => {
      const { featureSwitchOverrides } = DevToolsSettings.useFeatureSwitchOverrides();
      return featureSwitchOverrides;
    },
    featureSwitchesFetcherQueryParams: [
      "parentId",
      "parentLocale",
      "parentSwitches",
      "schoolId",
      "schoolSwitches",
      "sessionId",
      "sessionSwitches",
      // We need to support parents with multiple schools, but there is not a good way
      // to have dynamic query params. So we just hardcode the first 10 schools.
      "school0Id",
      "school0Switches",
      "school1Id",
      "school1Switches",
      "school2Id",
      "school2Switches",
      "school3Id",
      "school3Switches",
      "school4Id",
      "school4Switches",
      "school5Id",
      "school5Switches",
      "school6Id",
      "school6Switches",
      "school7Id",
      "school7Switches",
      "school8Id",
      "school8Switches",
      "school9Id",
      "school9Switches",
    ],
  });
}
