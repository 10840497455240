import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import { useCallback, useMemo, type MutableRefObject } from "react";
import { NOOP } from "../../reactQuery";
import { MessageThread, AugmentedMessageThread } from "../types";
import { useMessageThreadFetcher } from "./fetchers";
import useWatch from "@classdojo/web/hooks/useWatch";
import { translate } from "@classdojo/web/utils/translate";
import { FetchNextPageOptions } from "@tanstack/react-query";

export const ENABLED_THREAD_TYPES = ["a", "b", "c", "d", "e", "f", "g", "h", "t"];

type ThreadFetcherResult = {
  data?: MessageThread[];
  isFetching: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => void;
  hasNextPage?: boolean;
};

export enum ErrorType {
  ForbiddenThread = "ForbiddenThread",
}

export const useThreads = ({
  messageThreadId,
  fetcherResult,
  accessibleThreadTypes = ENABLED_THREAD_TYPES,
  onErrorCallback,
  disableInfiniteFetch,
  showClassChatWelcomeMessage,
}: {
  messageThreadId?: string;
  fetcherResult: ThreadFetcherResult;
  onErrorCallback?: Record<ErrorType, () => void>;
  accessibleThreadTypes?: string[];
  disableInfiniteFetch?: boolean;
  showClassChatWelcomeMessage?: MutableRefObject<boolean>;
}) => {
  const {
    data: threads,
    isFetching: loadingThreads,
    fetchNextPage: loadMoreThreads,
    hasNextPage: hasMoreThreads,
  } = fetcherResult;

  // need filtered participants with permission none and show deleted users
  // filter out threads based on thread type feature switch
  const augmentedThreads = useMemo(() => {
    if (!threads) {
      return;
    }

    const augmented: AugmentedMessageThread[] = [];
    for (const thread of threads) {
      if (accessibleThreadTypes.includes(thread.type)) {
        const t = {
          ...thread,
          activeParticipants: thread.participants.filter((p) => p.deleted || p.permission !== "none"),
        };

        if (showClassChatWelcomeMessage?.current && thread.type === "e") {
          t.formattedLastMessage = translate(
            "dojo.common:messaging.thread_list.auto_class_chat_last_message_override",
            "Welcome to the group",
          );
          t.unreadCount = 1;
          showClassChatWelcomeMessage.current = false;
        }

        augmented.push(t);
      }
    }

    return augmented;
  }, [accessibleThreadTypes, showClassChatWelcomeMessage, threads]);

  const selectedThreadFromAugmentedThreads = useMemo(
    () => augmentedThreads?.find((t) => t._id === messageThreadId),
    [augmentedThreads, messageThreadId],
  );

  // Not using this for selectedThread because it would require the user to wait for the thread to load,
  // but we already have the thread in the threads list.
  // However, we do want to activate this fetcher so that it triggers actions when the thread is updated.
  const { data: selectedThreadFromLoaded } = useMessageThreadFetcher(messageThreadId ? { messageThreadId } : NOOP, {
    onError: (error) => {
      if (error instanceof APIResponseError) {
        const errorType = error.response?.status === 403 ? ErrorType.ForbiddenThread : undefined;
        if (errorType && onErrorCallback) {
          onErrorCallback[errorType]?.();
        }
      }
    },
  });

  // Using Object.assign to keep the same reference to the object so that the component does not re-render.
  const augmentedSelectedThreadFromLoaded = useMemo(
    () =>
      selectedThreadFromLoaded &&
      Object.assign(selectedThreadFromLoaded, {
        activeParticipants: selectedThreadFromLoaded.participants.filter((p) => p.deleted || p.permission !== "none"),
      }),
    [selectedThreadFromLoaded],
  );

  // We do this dance here so that the user does not need to wait for the thread to load, but also eventually sees
  // the most up to date version of the thread.
  const selectedThread = useMemo(
    () => augmentedSelectedThreadFromLoaded || selectedThreadFromAugmentedThreads,
    [augmentedSelectedThreadFromLoaded, selectedThreadFromAugmentedThreads],
  );

  const loadMoreThreadsIfAvailable = useCallback(() => {
    if (hasMoreThreads && !loadingThreads) {
      loadMoreThreads({ cancelRefetch: false });
    }
  }, [hasMoreThreads, loadMoreThreads, loadingThreads]);

  useWatch([hasMoreThreads, loadMoreThreads, loadingThreads], () => {
    if (!disableInfiniteFetch) {
      loadMoreThreadsIfAvailable();
    }
  });

  return {
    loadingThreads: !augmentedThreads && loadingThreads,
    selectedThread,
    threads: augmentedThreads,
    loadMoreThreads: loadMoreThreadsIfAvailable,
    isLoadingMoreThreads: loadingThreads,
    hasMoreThreads,
  };
};
