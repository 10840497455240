import useWatch from "@classdojo/web/hooks/useWatch";
import env from "app/utils/env";
import { useFetchedFeatureSwitch } from "@web-monorepo/shared/featureSwitches";
import { FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";
import { registerServiceWorker, unregisterServiceWorker } from "@web-monorepo/vite-retry-imports-plugin";
import { addBreadcrumb } from "@web-monorepo/telemetry";

export function RetryAssetRegistration() {
  const enabled = useFetchedFeatureSwitch(FEATURE_SWITCHES.WEB_HOME_RETRY_ASSET_WORKER) === "test";

  useWatch(enabled, (value) => {
    if (!env.isProd) return;
    if (env.isCypress) return;
    if (!("serviceWorker" in navigator)) return;

    if (value) {
      registerServiceWorker()
        .then((m: any) => {
          addBreadcrumb({ category: "infra", message: `Loaded ServiceWorker with scope: ${m.scope}`, level: "info" });
        })
        .catch((_x: Error) => {
          addBreadcrumb({ category: "infra", message: `Failed to register service worker`, level: "warning" });
        });
    } else {
      unregisterServiceWorker()
        .then((unregistered: boolean) => {
          if (unregistered) {
            addBreadcrumb({ category: "infra", message: "Unregistered ServiceWorker", level: "info" });
            document.location.reload();
          }
        })
        .catch((_x: Error) => {
          addBreadcrumb({ category: "infra", message: `Failed to unregister service worker`, level: "warning" });
        });
    }
  });

  return null;
}
