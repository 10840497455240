import { Response } from "superagent";
import getApiError from "app/utils/getApiError";

export default function matchesError(response: Response, match: string) {
  const error = getApiError(response);

  if (!error) {
    return false;
  }

  return error.indexOf(match.trim().toLowerCase()) !== -1;
}
